/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AuthService from '../../services/authServices';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import AnimatedSuccessGIF from "../../assets/images/success.gif";
import { Message } from 'primereact/message';

import { useParams } from 'react-router-dom';


import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import logoLight from "../../assets/images/wiremit-facial-recognition.png";
import Navbar1 from "../../layout/Navbar";

AOS.init({
  duration: 1800,
});

const Section = () => {


  const RegistrationForm = () => {
    return (<></>)
  }

  const LoadingSkeleton = () => {
    return (
      <div className="border-round border-1 surface-border p-4 surface-card">
        <div className="flex mb-3">
          <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
          <div>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height=".5rem"></Skeleton>
          </div>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
      </div>)
  }

  const viewList = [
    <RegistrationForm />,
    <LoadingSkeleton />,
  ]

  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);

  const [emailVerificationCode, setemailVerificationCode] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  const { activationCode } = useParams();

  useEffect(() => {
    setemailVerificationCode(activationCode)
  }, [activationCode]);

  const confirmRegistration = async (e) => {
    e.preventDefault();

    try {
      const requestBody = {
        "username": emailAddress,
        "emailVerificationCode": emailVerificationCode
      }

      const response = await AuthService.confirmRegistration(requestBody);

      if (response.status === "SUCCESS") {
        navigate('/');
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Activation Failed',
          detail: response.message,
          life: 4000
        })
        console.error('Activation failed:', response.message);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Activation Failed',
        detail: "New account activation failed. Please try again in a moment.",
        life: 4000
      })
      console.error('Error:', error);
    }
  };


  return (


    <React.Fragment>
      <Navbar1 />
      <div className="pb-4 home-2 bg-home d-flex bg-light" id="home" style={{ width: 'auto' }}>
        <Toast ref={toast} />
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col md={5}>
              <h3 className="home-title"><span style={{ color: "#2e3664", fontWeight: "400" }}>Welcome to Wiremit</span><br /><span style={{ color: "#268e9c", fontWeight: "100", fontSize:"x-large" }}>Account activation</span></h3>
            </Col>

            <Col md={7} >
              <div className="reg-move mt-5 contact glassmorphic-container-curve ">
                <Container className=''>
 
                          <Row className="align-items-center justify-content-center">

                            <div className="contact-box">
                              <div className="custom-form mt-4">

                              <Row className="align-items-center justify-content-center">
                                  <div className="contact-box">
                                    <div className="custom-form ">
                                      <form method="post" name="myForm" >
                                        <p id="error-msg" style={{ opacity: 1 }}> </p>
                                        <Message className='mb-3' severity="success" text={"Please enter your email address to activate account." } />
                                        <Row className='mt-3'>
                                          <Col lg={4}>
                                            <div className="form-group">
                                            
                                              <input
                                                name="verificationCode"
                                                id="verificationCode"
                                                type="text"
                                                className="form-control contact-form"
                                                placeholder='Enter Verification code'
                                                value={emailVerificationCode}
                                                onChange={(e) => { setemailVerificationCode(e.target.value) }}
                                                disabled
                                              />
                                            </div>
                                          </Col>

                                          <Col lg={8}>
                                            <div className="form-group">
                                            
                                              <input
                                                name="emailAddress"
                                                id="emailAddress"
                                                type="text"
                                                className="form-control contact-form"
                                                placeholder='Enter email address'
                                                value={emailAddress}
                                                onChange={(e) => { setEmailAddress(e.target.value) }}
                                                
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row className="justify-content-center">
                                          <div className="col-lg-6 mt-4 mb-4">
                                            <div className="text-center">
                                              <Button onClick={(e) => confirmRegistration(e)} className="btn btn-large bg-primary me-2 ">
                                                Activate Account
                                              </Button>
                                            </div>
                                          </div>
                                        </Row>
                                      </form>
                                    </div>
                                  </div>
                                </Row>

                              </div>
                            </div>
                          </Row>

                  
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Section;
