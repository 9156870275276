import axios from 'axios';
import { getEndpoint } from './endpoint';

const ackWiremitInternationalServiceEndpoint = getEndpoint();

const config = {
  baseURL: `${ackWiremitInternationalServiceEndpoint}/remitone`,
  headers: {
    'Content-Type': 'application/json',
  },
};

async function register(data) {

  const requestBody = {
    "operation": "login",
    "accessToken": "",
    "requestBody": data
  }

  try {
    const res = await axios.post('/user/register', requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please try again in a moment.' };
  }
}

async function confirmRegistration(data) {
  try {
    const requestBody = {
      "operation": "confirmRegistration",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post('/user/confirmRegistration', requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}

async function resetPassword(data) {
  try {
    const requestBody = {
      "operation": "resetPassword",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post('/user/forgotPassword', requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please try again in a moment.' };
  }
}

async function setNewPassword(data) {
  try {
    const requestBody = {
      "operation": "setPassword",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post('/user/changePassword', requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please try again later.' };
  }
}

async function login(data) {
  try {
    const requestBody = {
      "operation": "login",
      "accessToken": "",
      "requestBody": data
    }
    const res = await axios.post('/user/login', requestBody, config);

    return getProfile(data, res.data.responseBody);
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function getProfile(loginData, responseBody) {
  try {
    if (responseBody.status === "SUCCESS") {
      const requestBody = {
        "operation": "getProfile",
        "accessToken": "",
        "requestBody": {
          "username": loginData.username,
          "sessionToken": responseBody.session_token[0]
        }

      }

      const res = await axios.post('/user/getProfile', requestBody, config);
      res.data.responseBody.sessionToken = responseBody.session_token[0];
      localStorage.setItem('sessionToken', responseBody.session_token[0]);
      localStorage.setItem('ratesSessionToken', responseBody.session_token[0]);
      localStorage.setItem('username', loginData.username);
      localStorage.setItem('user', (JSON.stringify(res.data.responseBody.result.remitter)));
      return res.data.responseBody;
    }

    return responseBody;

  } catch (err) {
    console.error(err);
    return err;
  }
}

async function uploadKyc(base64String) {
  try {

    const requestBody = {
      "operation": "Upload KYC Video",
      "accessToken": "",
      "requestBody": {
        "username": localStorage.getItem('username'),
        "sessionToken": localStorage.getItem('sessionToken'),
        "kyc_video": base64String,
        "filename": localStorage.getItem('sessionToken').substring(1, 8) + ".webm"
      }
    }
    const res = await axios.post('/user/kyc', requestBody, config);

    return res.data.responseBody;

  } catch (err) {
    console.error(err);
    return err;
  }
}

async function getKyc() {
  try {

    const requestBody = {
      "operation": "Upload KYC Video",
      "accessToken": "",
      "requestBody": {
        "username": localStorage.getItem('username'),
        "sessionToken": localStorage.getItem('sessionToken'),
      }
    }
    const res = await axios.post('/user/kyc/video', requestBody, config);

    return res.data.responseBody;

  } catch (err) {
    console.error(err);
    return err;
  }
}

async function uploadPOA(base64String) {
  try {

    const requestBody = {
      "operation": "Upload POA ",
      "accessToken": "",
      "requestBody": {
        "username": localStorage.getItem('username'),
        "sessionToken": localStorage.getItem('sessionToken'),
        "id2_scan": base64String,
      }
    }
    const res = await axios.post('/user/updateProfile', requestBody, config);

    return res.data.responseBody;

  } catch (err) {
    console.error(err);
    return err;
  }
}

async function getRemitterUISettings(sourceCountry) {

  const requestBody = {
    "operation": "get ui settings",
    "requestBody": {
      "sourceCountryId": sourceCountry,
      "registrationType": "registered"
    }
  }

  try {
    const res = await axios.post('/user/getRemitterUISettings', requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}

async function getLiveIDVerification(uuid) {
  try {
    const requestBody = {
      "operation": "live id verfirication",
      "accessToken": "",
      "requestBody": {
        "sessionId": uuid
      }
    }
    const res = await axios.post('/user/getLiveIDVerification', requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}

const AuthService = {
  register,
  confirmRegistration,
  login,
  uploadKyc,
  uploadPOA,
  getKyc,
  getRemitterUISettings,
  getLiveIDVerification,
  resetPassword,
  setNewPassword
};

export default AuthService;
