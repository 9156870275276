import axios from 'axios';
import { getEndpoint } from './endpoint';

const ackWiremitInternationalServiceEndpoint = getEndpoint();

const config = {
  baseURL: `${ackWiremitInternationalServiceEndpoint}/remitone`,
  headers: {
    'Content-Type': 'application/json',
  },
};

async function getBeneficiary() {
  try {
    const requestBody = {
      "operation": "getBeneficiary",
      "accessToken": "",
      "requestBody": {
        "username": localStorage.getItem('username'),
        "sessionToken": localStorage.getItem('sessionToken')
      }
    }

    // return await axios.post('/beneficiary/all', requestBody, config);  
    return (await axios.post('/beneficiary/all', requestBody, config)).data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}

async function addNewBeneficiary(data) {
  try {
    data.username =localStorage.getItem('username');
    data.sessionToken= localStorage.getItem('sessionToken');
    const requestBody = {
      "operation": "Add Beneficiary",
      "accessToken": "",
      "requestBody": data
    }
    return await axios.post('/beneficiary/add', requestBody, config); 
  } catch (err) {
    console.error(err);
    return err;
  }
}


async function editBeneficiary(data) {
  try {
    data.username =localStorage.getItem('username');
    data.sessionToken= localStorage.getItem('sessionToken');
    const requestBody = {
      "operation": "Edit Beneficiary",
      "accessToken": "",
      "requestBody": data
    }
    return await axios.post('/beneficiary/update', requestBody, config); 
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function getCollectionPoints() {
  try {
    const requestBody = {
      "operation": "getCollectionPoints",
      "accessToken": "",
      "requestBody": {
        "username": localStorage.getItem('username'),
        "sessionToken": localStorage.getItem('sessionToken'),
        "destination_country_id": "947",
      }
    } 


    return (await axios.post('/transaction/getCollectionPoints', requestBody, config)).data; 
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}
const BenService = {
  getBeneficiary,
  addNewBeneficiary,
  editBeneficiary,
  getCollectionPoints,
  getTreeNodesData() {
    return [
      {
        key: '0',
        label: 'Documents',
        data: 'Documents Folder',
        icon: 'pi pi-fw pi-inbox',
        children: [
          {
            key: '0-0',
            label: 'Work',
            data: 'Work Folder',
            icon: 'pi pi-fw pi-cog',
            children: [
              { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
              { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
            ]
          },
          {
            key: '0-1',
            label: 'Home',
            data: 'Home Folder',
            icon: 'pi pi-fw pi-home',
            children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
          }
        ]
      },
      {
        key: '1',
        label: 'Events',
        data: 'Events Folder',
        icon: 'pi pi-fw pi-calendar',
        children: [
          { key: '1-0', label: 'Meeting', icon: 'pi pi-fw pi-calendar-plus', data: 'Meeting' },
          { key: '1-1', label: 'Product Launch', icon: 'pi pi-fw pi-calendar-plus', data: 'Product Launch' },
          { key: '1-2', label: 'Report Review', icon: 'pi pi-fw pi-calendar-plus', data: 'Report Review' }
        ]
      },
      {
        key: '2',
        label: 'Movies',
        data: 'Movies Folder',
        icon: 'pi pi-fw pi-star-fill',
        children: [
          {
            key: '2-0',
            icon: 'pi pi-fw pi-star-fill',
            label: 'Al Pacino',
            data: 'Pacino Movies',
            children: [
              { key: '2-0-0', label: 'Scarface', icon: 'pi pi-fw pi-video', data: 'Scarface Movie' },
              { key: '2-0-1', label: 'Serpico', icon: 'pi pi-fw pi-video', data: 'Serpico Movie' }
            ]
          },
          {
            key: '2-1',
            label: 'Robert De Niro',
            icon: 'pi pi-fw pi-star-fill',
            data: 'De Niro Movies',
            children: [
              { key: '2-1-0', label: 'Goodfellas', icon: 'pi pi-fw pi-video', data: 'Goodfellas Movie' },
              { key: '2-1-1', label: 'Untouchables', icon: 'pi pi-fw pi-video', data: 'Untouchables Movie' }
            ]
          }
        ]
      }
    ];
  },

  async getTreeTableNodesData() {
    const beneficiaryListBody = await getBeneficiary();
    const beneficiaryList = [];
    console.log(beneficiaryListBody.responseBody.result)
    console.log("------->>>>>")
    if (beneficiaryListBody.responseBody) {
      beneficiaryListBody.responseBody.result.beneficiaries.beneficiary.map((beneficiary, index) => (
        beneficiaryList.push({
          key: beneficiary.beneficiary_id, 
            label: 'Work', 
            icon: 'pi pi-fw pi-cog',
          data: {
            name: beneficiary.name,
            country: beneficiary.country, 
            email: beneficiary.email,
            mobile: beneficiary.mobile,
            import_id: beneficiary.import_id
          },
          children: [
            {
              key: beneficiary.beneficiary_id,
              data: {
                mobile: beneficiary.mobile,
                email: beneficiary.email,
                import_id: beneficiary.import_id
              }
            }
          ]
        },)
      ));
    } 

    return beneficiaryList;
  },

  getTreeTableNodes() {
    return Promise.resolve(this.getTreeTableNodesData());
  },

  getTreeNodes() {
    return Promise.resolve(this.getTreeNodesData());
  }
};

export default BenService;
