import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter } from 'react-router-dom';
import 'react-dropdown/style.css';
import { PrimeReactProvider } from 'primereact/api';

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
  gtmId: 'AW-11464517865',
  dataLayer: {
      userId: '001',
      userProject: 'wiremit-international'
  }
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  <React.Fragment>
    <HashRouter>
      <PrimeReactProvider value={{ appendTo: 'self' }}>
        <App />
      </PrimeReactProvider>
    </HashRouter>
  </React.Fragment>,
  document.getElementById('root')
);
