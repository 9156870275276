/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react'
import {
    Navbar,
    Nav,
    Container,
    Row,
    Col,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// import Image
import logoLight from "../assets/images/logo-light.png";
import logoDark from "../assets/images/logo-dark.png";

const Navbar1 = () => {

    const [activeItem, setActiveItem] = useState('Home');

    const [navClass, setnavClass] = useState("");

    const location = useLocation();


    // navbar Scroll

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
    });

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("nav-sticky");
        } else {
            setnavClass("");
        }
    }

    // toggle

    const navMenuRef = useRef(null);

    const toggleNavMenu = () => {
        navMenuRef.current.classList.toggle('collapse');
    };


    return (
        <React.Fragment>
            <div data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="61" data-bs-smooth-scroll="true" className="scrollspy-example-2">
                <nav className={`navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light ${navClass} `}
                    id="navbar">
                    <Container fluid>
                        <Navbar.Brand href="/" className="logo text-uppercase">
                            <img src={logoLight} className="logo-light" alt="" height="50" />
                            <img src={logoDark} className="logo-dark" alt="" height="50" />
                        </Navbar.Brand>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleNavMenu}>
                            <span className="mdi mdi-menu"></span>
                        </button>

                        <div ref={navMenuRef} className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav ms-auto" id="navbar-navlist">
                                {/* <li className={activeItem === 'Home' ? 'active' : 'nav-item'} onClick={() => setActiveItem('Home')} >
                                    <Nav.Link href="/">Home</Nav.Link>
                                </li>
                                <li className={activeItem === 'Features' ? 'active' : 'nav-item'} onClick={() => setActiveItem('Features')}>
                                    <Nav.Link href="/">Sign In</Nav.Link>
                                </li>
                                <li className={activeItem === 'Pricing' ? 'active' : 'nav-item'} onClick={() => setActiveItem('Pricing')}>
                                    <Nav.Link href="/new-user">Register Now</Nav.Link>
                                </li> */}
                                {/* <li className={activeItem === 'Contact' ? 'active' : 'nav-item'} onClick={() => setActiveItem('Contact')}>
                                    <Nav.Link href="#contact">Contact</Nav.Link>
                                </li> */}
                            </ul>

                            <div className="ms-auto">
                            {location.pathname === '/'?
                            <Link to="/new-user" className="btn btn-lg bg-primary m-3">Sign up Now</Link>:
                            <Link to="/" className="btn m-1">Login</Link>
                            }
                                {/* <Link to="wiremit.money" target='blank' className="justify-content-center text-primary fw-bold  m-3">
                                    About Us 
                                </Link> */}
                            </div>
                        </div>
                    </Container>
                </nav>
            </div>
        </React.Fragment>
    )
}

export default Navbar1;

