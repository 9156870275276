import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from '../layout/index';
import authProtectedRoutes from './allRoutes';
import Footer from '../layout/Footer';

const Index = () => {
  const location = useLocation();
  const excludeFooterRoutes = ['/currency-convertor'];

  const shouldShowFooter = !excludeFooterRoutes.includes(location.pathname);

  return (
    <React.Fragment>
      <Routes>
        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Layout>{route.component}</Layout>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route>
      </Routes>

      {/* Conditionally render the footer based on the route */}
      {shouldShowFooter && <Footer />}
    </React.Fragment>
  );
};

export default Index;
