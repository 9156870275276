import React   from 'react'
import Home from '../pages/Home/home';
import Dashboard from '../pages/Dashboard/dashboard';
import Signup from '../pages/Signup/signup';
import Verify from '../pages/Signup/verify';
import ResetPassword from '../pages/Home/reset-password';
import SendMoney from '../pages/SendMoney/sendMoney';
import Success from '../pages/Success/success';
import IFrameCurrencyConvertorExternal from '../pages/Home/iFrameCurrencyConvertorExternal';

const authProtectedRoutes = [
    // Lauout1
    { path: "/", component: <Home/> },

    {path:"/currency-convertor", component:<IFrameCurrencyConvertorExternal/>},

    // Dashboard
    { path: "/dashboard", component: <Dashboard/> },

    // Signup
    { path: "/new-user", component: <Signup/> },

    // Send money
    { path:"/send-money", component: <SendMoney /> },

    { path:"/confirmation", component: <Success /> },

    { path: "/verify/:activationCode", component: <Verify/> },

    { path:"/forgot-password", component: <ResetPassword /> },
]

export default authProtectedRoutes;