/* eslint-disable no-unused-vars */

/* eslint-disable eqeqeq */

import React, { useState, useRef } from "react";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useMountEffect } from "primereact/hooks";

export default function TitleComponent({
  titles,
  moveToPreviousView,
  activeView,
  userPolicyInput,
}) {
  const handlePrevious = () => {
    moveToPreviousView();
  };

  const menuLeft = useRef(null);
  const [savedUserInput, setSavedUserInput] = useState([]);

  return (
    <>
      <div
        style={{ marginTop: "2%" }}
        className="col-12 row d-flex justify-content-center mt- align-items-center"
      >
        <div className="">
          <>
            <div className="row">
              {activeView !== 0 ? (
                <div className="col-md-1 justify-content-start">
                  <Button
                    className="btn bg-sucess me-2 mt-4"
                    size="large"
                    label="Back"
                    onClick={handlePrevious}
                  />
                </div>
              ) : (
                <div className="col-md-1 justify-content-start"></div>
              )}
              <div className="col-10 d-flex d-flex justify-content-center mt-8 align-items-center">
                <div className="text-center">
                  <h3 className="mt-1 mb-2">{titles.topHeading}</h3>
                  <h4 className="mt-1 mb-2 --blue">
                    <span style={{ color: "#268e9c", fontWeight: "600" }}>
                      {titles.middleHeading}
                    </span>
                  </h4>
                </div>
              </div>
              {/* <div className='col-1 d-flex justify-content-end'>
                            <Menu model={savedUserInput} popup ref={menuLeft} id="popup_menu_left" />
                            <Button icon="pi pi-info-circle" rounded outlined severity="info" onClick={(event) => menuLeft.current.toggle(event)} aria-label="info" aria-controls="popup_menu_left" aria-haspopup />
                        </div> */}
            </div>
            <div className="">
              <Divider type="dotted" />
            </div>
          </>
        </div>
      </div>
    </>
  );
}
