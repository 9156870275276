/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AuthService from '../../services/authServices';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import AnimatedSuccessGIF from "../../assets/images/success.gif";
import { Message } from 'primereact/message';

import { useParams } from 'react-router-dom';


import countryList from "react-select-country-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import logoLight from "../../assets/images/wiremit-facial-recognition.png";
import Navbar1 from "../../layout/Navbar";

AOS.init({
  duration: 1800,
});

const Section = () => {


  const RegistrationForm = () => {
    return (<></>)
  }

  const LoadingSkeleton = () => {
    return (
      <div className="border-round border-1 surface-border mt-2 surface-card">
        <Skeleton width="80%" height="45px"></Skeleton>
      </div>)
  }


  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);

  const [emailVerificationCode, setemailVerificationCode] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [dob, setDob] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [displayVerificationCodeField, setDisplayVerificationCodeField] = useState(false);


  useEffect(() => {

  }, []);

  const sendNewPassword = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const requestBody = {
        "username": emailAddress,
        "forgot_password_token": emailVerificationCode,
        "new_password": newPassword
      }

      const response = await AuthService.setNewPassword(requestBody);

      if (response.status === "SUCCESS") {
        toast.current.show({
          severity: 'success',
          summary: 'Password Reset successful',
          detail: response.message,
          life: 4000
        })
        setLoading(false);
        navigate('/');
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Password Reset failed',
          detail: response.message,
          life: 4000
        })
        setLoading(false);
        console.error('Password Reset failed:', response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.current.show({
        severity: 'error',
        summary: 'Activation Failed',
        detail: "New account activation failed. Please try again in a moment.",
        life: 4000
      })
      console.error('Error:', error);
    }
  };

  const sendRegistrationLink = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const requestBody = {
        "username": emailAddress,
        "dob":dob
      }

      const response = await AuthService.resetPassword(requestBody);

      if (response.status === "SUCCESS") {

        setDisplayVerificationCodeField(true);
        setLoading(false);
      } else {
        setLoading(false)
        toast.current.show({
          severity: 'error',
          summary: 'Reset Failed',
          detail: response.message,
          life: 4000
        })
        console.error('Activation failed:', response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.current.show({
        severity: 'error',
        summary: 'Activation Failed',
        detail: "Account reset failed. Please try again in a moment.",
        life: 4000
      })
      console.error('Error:', error);
    }
  };



  return (


    <React.Fragment>
      <Navbar1 />
      <div className="pb-4 home-2 bg-home d-flex bg-light" id="home" style={{ width: 'auto' }}>
        <Toast ref={toast} />
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col md={5}>
              <h3 className="home-title"><span style={{ color: "#2e3664", fontWeight: "400" }}>Wiremit Password Reset</span><br /><span style={{ color: "#268e9c", fontWeight: "60", fontSize:"x-large" }}>Account activation</span></h3>
            </Col>

            <Col md={7} >
              <div className="reg-move mt-5 contact glassmorphic-container-curve ">
                <Container className=''>
 
                          <Row className="align-items-center justify-content-center">

                            <div className="contact-box">
                              <div className="custom-form mt-4">

                              <Row className="align-items-center justify-content-center">
                                  <div className="contact-box">
                                    <div className="custom-form ">
                                      <form method="post" name="myForm" >
                                        <p id="error-msg" style={{ opacity: 1 }}> </p>

                                       {!displayVerificationCodeField? 
                                       //Display when email is not sent

                                       !loading?
                                       <>
                                        <Message className='mb-3' text={"Please enter your email address and date of birth to reset account password." } />
                                          <Row className='mt-3'>
                                          <Col lg={6}>
                                            <div className="form-group">
                                              <input
                                                name="emailAddress"
                                                id="emailAddress"
                                                type="text"
                                                className="form-control contact-form"
                                                placeholder='Enter email address'
                                                value={emailAddress}
                                                onChange={(e) => { setEmailAddress(e.target.value) }}
                                                
                                              />
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="form-group">
                                              <input
                                                name="dob"
                                                id="dob"
                                                type="text"
                                                className="form-control contact-form"
                                                placeholder='Enter DOB (yyyy-mm-dd)'
                                                value={dob}
                                                onChange={(e) => { setDob(e.target.value) }}
                                                
                                              />
                                            </div>
                                          </Col>
                                          <Row className="justify-content-center">
                                            <div className="col-lg-6 mb-4">
                                              <div className="text-center">
                                                <Button onClick={(e) => sendRegistrationLink(e)} className="btn btn-large bg-primary me-2 ">
                                                  Reset Password
                                                </Button>
                                              </div>
                                            </div>
                                          </Row>
                                        </Row>
                                       </>
                                       
                                        :
                                        <LoadingSkeleton/>
                                        :

                                        // Display when email is sent

                                        !loading?

                                        <>
                                         <Message className='mb-3' text={"Please check your email and enter the code." } />
                                         <Row className='mt-3'>
                                          <Col lg={4}>
                                              <div className="form-group">
                                              
                                                <input
                                                  name="verificationCode"
                                                  id="verificationCode"
                                                  type="text"
                                                  className="form-control contact-form"
                                                  placeholder='Enter Verification code'
                                                  value={emailVerificationCode}
                                                  onChange={(e) => { setemailVerificationCode(e.target.value) }}
                                                />
                                              </div>
                                          </Col>
                                          <Col lg={8}>
                                          <input
                                                name="newPassword"
                                                id="newPassword"
                                                type="text"
                                                className="form-control contact-form"
                                                placeholder='Enter new password'
                                                value={newPassword}
                                                onChange={(e) => { setNewPassword(e.target.value) }}
                                                
                                              /></Col>
                                          <Row className="justify-content-center">
                                              <div className="col-lg-6 mb-4">
                                                <div className="text-center">
                                                  <Button onClick={(e) => sendNewPassword(e)} className="btn btn-large bg-primary me-2 ">
                                                    Activate Account
                                                  </Button>
                                                </div>
                                              </div>
                                          </Row>
                                        </Row>                                     
                                        </>

                                        :
                                         <LoadingSkeleton/>}
                                      
                                        
                                      </form>
                                    </div>
                                  </div>
                                </Row>

                              </div>
                            </div>
                          </Row>

                  
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Section;
