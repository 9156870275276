import axios from 'axios';
import { getEndpoint } from './endpoint';

const ackWiremitInternationalServiceEndpoint = getEndpoint();

const config = {
  baseURL: `${ackWiremitInternationalServiceEndpoint}/remitone`,
  headers: {
    'Content-Type': 'application/json',
  },
};


async function initiateTransaction(data) {
  try {
    data.username = localStorage.getItem('username');
    data.sessionToken = localStorage.getItem('sessionToken');
    const requestBody = {
      "operation": "Create Transaction",
      "accessToken": "",
      "requestBody": data
    }
    console.log(requestBody);
    return (await axios.post('/transaction/create', requestBody, config)).data;
  } catch (err) {
    console.error(err);
    return err;
  }
}

async function confirmTransaction(requestBodyData) {
  try {
    requestBodyData.username = localStorage.getItem('username');
    requestBodyData.sessionToken = localStorage.getItem('sessionToken');
    const requestBody = {
      "operation": "Confirm Transaction",
      "accessToken": "",
      "requestBody": requestBodyData
    }
    return (await axios.post('/transaction/confirm', requestBody, config)).data;
  } catch (err) {
    alert(err)
    console.error(err);
    return err;
  }
}

async function getUISettings(toCountry) {

  const requestBody = {
    "operation": "Create Transation",
    "accessToken": localStorage.getItem('sessionToken'),
    "requestBody": {
      "destination_country_id": toCountry,
      "username": localStorage.getItem('username'),
      "sessionToken": localStorage.getItem('sessionToken')
    }
  }

  try {
    const res = await axios.post('/transaction/getUISettings', requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}

async function getDestinationCountries() {

  const requestBody = {
    "operation": "Get destination countries",
    "accessToken": localStorage.getItem('sessionToken'),
    "requestBody": {
      "username": localStorage.getItem('username'),
      "sessionToken": localStorage.getItem('sessionToken')
    }
  }

  try {
    const res = await axios.post('/transaction/getDestinationCountries', requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}


async function getRates(destinationCountry, sourceCurrency, destinationCurrency, amount, amountType) {

  const requestBody = {
    "operation": "Create Transation",
    "accessToken": localStorage.getItem('ratesSessionToken'),
    "requestBody": {
      "username": localStorage.getItem('username'),
      "sessionToken": localStorage.getItem('ratesSessionToken'),
      "destination_country": destinationCountry,
      "trans_type": "Cash Collection",
      "payment_method": 11,
      "service_level": 1,
      "sms_confirmation": "f",
      "sms_notification": "t",
      "amount_type": amountType, //SOURCE,
      "amount_to_send": amount,
      "source_currency": sourceCurrency,
      "destination_currency": destinationCurrency
    }
  }

  try {
    const res = await axios.post('/transaction/getCharges', requestBody, config);
    return res.data;
  } catch (err) {
    console.error(err);
    return { success: false, message: 'Connection failed. Please check your connection.' };
  }
}

async function getUserTransactions() {
  try {

    var requestBody = {
      "operation": "Get Transactions",
      "accessToken": "",
      "requestBody": { 
          "destination_country_id": "947",
          "username": localStorage.getItem('username'),
          "sessionToken": localStorage.getItem('sessionToken'),
      }
    }

    const res = await axios.post('/transaction/getHistoryList', requestBody, config);
  
    return res.data;
  } catch (err) {
    console.error(err);
    return err;
  }
}


async function checkTransactionStatus(transRef) {

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${ackWiremitInternationalServiceEndpoint}/remitone/transaction/checkTransactionStatus/${transRef}`,
    headers: { 'Content-Type': 'application/json',}
  };
  
 const response = await axios.request(config)
 
 return response.data;
}

const TransactionService = {
  initiateTransaction,
  confirmTransaction,
  getRates,
  getUserTransactions,
  getUISettings,
  getDestinationCountries,
  checkTransactionStatus
}


export default TransactionService;
