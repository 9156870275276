import React, { useState, useEffect } from "react";
import { FaEquals, FaPlus } from "react-icons/fa";
import "./CalculatorForm.css";

const CurrencyConvertor = () => {
  const defaultExchangeRate = { GBPUSD: 1.26 };
  const commissionRate = 0; // Commission rate: 0%
  const fixedFee = 2.5; // Fixed fee: 2.50 GBP
  const [selectedCurrencyPair, setSelectedCurrencyPair] = useState("GBPUSD"); // Default currency for receiver
  const [amountToSend, setAmountToSend] = useState("100.00"); // Default amount to send
  const [recipientAmount, setRecipientAmount] = useState("0"); // Default amount to receive
  const [showCalculation, setShowCalculation] = useState(true); // State to toggle visibility of calculation dropdown
  const [shouldRoundAmountToSend, setShouldRoundAmountToSend] = useState(false); // Flag to determine rounding for amount to send
  const [shouldRoundRecipientAmount, setShouldRoundRecipientAmount] =
    useState(true); // Flag to determine rounding for recipient amount

  const handleCurrencyPairChange = (currency) => {
    setSelectedCurrencyPair(currency);
  };

  const handleAmountChange = (event) => {
    let rawValue = event.target.value;
    const senderCurrency = "GBP"; //value.substring(1, 4); // Extract currency code from input value
    const recipientCurrency = "USD"; //recipientAmount.substring(1, 4); // Extract recipient currency code from recipientAmount
    const updatedCurrencyPair = senderCurrency + recipientCurrency; // Construct new currency pair
    setSelectedCurrencyPair(updatedCurrencyPair); // Update selected currency pair

    let value = rawValue.replace(/[^0-9.]/g, "");

    // Enforce maximum limit
    value = Math.min(parseFloat(value), 9999999);

    let parsedValue = parseFloat(value);
    if (parsedValue < 0) {
      parsedValue = 1;
    }

    if (isNaN(parsedValue)) {
      parsedValue = 0;
    }
    // Update the amountToSend state with the validated value
    setAmountToSend(parsedValue);

    // Calculate recipient amount
    const exchangeRateWithCommission =
      defaultExchangeRate[updatedCurrencyPair] * (1 + commissionRate);
    const calculatedRecipientAmount = (
      parsedValue /
      (1 / exchangeRateWithCommission)
    ).toFixed(2);
    setRecipientAmount(`${calculatedRecipientAmount}`);
  };

  const handleRecipientAmountChange = (event) => {
    let value = event.target.value;
    const senderCurrency = "GBP"; //value.substring(1, 4); // Extract sender currency code from input value
    const recipientCurrency = "USD"; //amountToSend.substring(1, 4); // Extract currency code from amountToSend
    const updatedCurrencyPair = senderCurrency + recipientCurrency; // Construct new currency pair
    setSelectedCurrencyPair(updatedCurrencyPair); // Update selected currency pair

    value = value.replace(/[^0-9.]/g, "");

    // Enforce maximum limit
    value = Math.min(parseFloat(value), 9999999);

    let parsedValue = parseFloat(value);
    if (parsedValue < 0) {
      parsedValue = 0;
    }

    if (isNaN(parsedValue)) {
      parsedValue = 0;
    }
    // Update the recipientAmount state with the validated value
    setRecipientAmount(parsedValue);

    // Calculate amount to send
    const exchangeRateWithCommission =
      defaultExchangeRate[updatedCurrencyPair] * (1 + commissionRate);
    const calculatedAmountToSend = (
      parsedValue *
      (1 / exchangeRateWithCommission)
    ).toFixed(2);
    setAmountToSend(`${calculatedAmountToSend}`);
  };

  let fee = "0";
  if (amountToSend && selectedCurrencyPair in defaultExchangeRate) {
    // Calculate fee (sum of commission and fixed fee)
    fee = fixedFee.toFixed(2);
  }

  const roundedAmountToSend =
    (shouldRoundAmountToSend
      ? parseFloat(amountToSend).toFixed(0)
      : amountToSend) || 0;
  const roundedRecipientAmount =
    (shouldRoundRecipientAmount
      ? parseFloat(recipientAmount).toFixed(0)
      : recipientAmount) || 0;

  useEffect(() => {
    // Calculate initial values on component mount
    const senderCurrency = "GBP";
    const recipientCurrency = "USD";
    const updatedCurrencyPair = senderCurrency + recipientCurrency;
    const exchangeRateWithCommission =
      defaultExchangeRate[updatedCurrencyPair] * (1 + commissionRate);

    const initialRecipientAmount = (
      parseFloat(amountToSend) * exchangeRateWithCommission
    ).toFixed(2);
    setRecipientAmount(initialRecipientAmount);
  }, []); // Empty dependency array triggers the effect only once on mount

  const onSendAmountEventBlur = () => {
    if (shouldRoundRecipientAmount) {
      const senderCurrency = "GBP";
      const recipientCurrency = "USD";
      const updatedCurrencyPair = senderCurrency + recipientCurrency;
      const exchangeRateWithCommission =
        defaultExchangeRate[updatedCurrencyPair] * (1 + commissionRate);

      const recalculatedAmountToSend = (
        parseFloat(roundedRecipientAmount) *
        (1 / exchangeRateWithCommission)
      ).toFixed(2);
      setAmountToSend(recalculatedAmountToSend);
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Recalculate amount to send if recipientAmount is rounded
      onSendAmountEventBlur();
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [shouldRoundRecipientAmount, roundedRecipientAmount, roundedAmountToSend]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Recalculate recipient amount if amountToSend is rounded
      if (shouldRoundAmountToSend) {
        const senderCurrency = "GBP";
        const recipientCurrency = "USD";
        const updatedCurrencyPair = senderCurrency + recipientCurrency;
        const exchangeRateWithCommission =
          defaultExchangeRate[updatedCurrencyPair] * (1 + commissionRate);

        const recalculatedRecipientAmount = (
          parseFloat(roundedAmountToSend) /
          (1 / exchangeRateWithCommission)
        ).toFixed(2);
        setRecipientAmount(recalculatedRecipientAmount);
      }
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [shouldRoundAmountToSend, roundedAmountToSend]);

  return (
    <>
      <div>
        <div
          className="send-money"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
              borderBottomRightRadius: "16px",
              borderBottomLeftRadius: "16px",
            }}
          >
            <div
              style={{
                background: "linear-gradient(90deg, #345d92 0%, #1c2452 100%)",
                padding: "10px",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
                color: "white",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontSize: "0.8rem",
                  marginBottom: "8px",
                  fontWeight: "bold",
                }}
              >
                Exchange Rate
              </div>

              <div style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                1 GBP = {defaultExchangeRate[selectedCurrencyPair].toFixed(2)}{" "}
                {/* {selectedCurrencyPair} */} USD
              </div>
            </div>

            <div style={{ borderRadius: "16px" }}>
              <div
                className="currency-area"
                style={{ padding: "20px", margin: "20px" }}
              >
                <div
                  style={{
                    textAlign: "left",
                  }}
                >
                  You send
                  <input
                    autoComplete="off"
                    id="receive-field-container-amount"
                    placeholder="-"
                    type="decimal"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={`🇬🇧 GBP ${roundedAmountToSend}`}
                    onChange={handleAmountChange}
                    onBlur={onSendAmountEventBlur}
                  />
                </div>
              </div>

              <div
                className="currency-area"
                style={{ padding: "20px", margin: "20px" }}
              >
                <div
                  style={{
                    textAlign: "left",
                  }}
                >
                  They get
                  <input
                    autoComplete="off"
                    id="receive-field-container-amount"
                    placeholder="-"
                    type="decimal"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={`🇿🇼 USD ${roundedRecipientAmount}`}
                    onChange={handleRecipientAmountChange}
                  />
                </div>
              </div>
              <div
                className="calculation"
                style={{ padding: "0px 0px 5px 5px", margin: "20px" }}
              >
                <div className="head-area">
                  {showCalculation && (
                    <div className="calculation-dropdown">
                      <div className="single-area d-flex align-items-center">
                        <div className="left-area">
                          <i>
                            <FaPlus />
                          </i>
                          <span className="mdr">{fee} GBP</span>
                        </div>
                        <div className="right-area">
                          <span className="mdr">Our Fee</span>
                        </div>
                      </div>
                      <div className="single-area d-flex align-items-center">
                        <div className="left-area">
                          <i>
                            <FaEquals />
                          </i>
                          <span className="mdr">
                            {(
                              parseFloat(roundedAmountToSend) + parseFloat(fee)
                            ).toFixed(2)}{" "}
                            GBP
                          </span>
                        </div>
                        <div className="right-area">
                          <span className="mdr">You Pay</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="btn-area mt-20" style={{ margin: "5%" }}>
                <button
                  className="cmn-btn w-100 shimmer-button"
                  onClick={() => {
                    window.location.href = "https://secure.wiremit.money/";
                  }}
                >
                  Pay{" "}
                  {(parseFloat(roundedAmountToSend) + parseFloat(fee)).toFixed(
                    2
                  )}{" "}
                  GBP
                </button>
                <br />
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrencyConvertor;
