
/* eslint-disable no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */

import React, { useState, useRef, useEffect } from 'react';
import { useMountEffect } from 'primereact/hooks';
import { ProgressBar } from 'primereact/progressbar';

import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import { Steps } from 'primereact/steps';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import TitleComponent from "./titleComponent";
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import Select from "react-select";
import ReactCountryFlag from "react-country-flag";
import CurrencyInput from "react-currency-input-field";

import BeneficiaryServices from '../../services/beneficiaryServices';
import TransactionService from '../../services/transactionServices';
import './styles.css'
import { Link } from 'react-router-dom';

AOS.init({
  duration: 1800,
});


const paymentMethodsList = [
  { value: '11', label: 'Open banking' }
];

const Section = () => {
  const LoadingSkeleton = () => {
    return (
      <div className="border-round border-1 surface-border p-4 surface-card">
        <div className="flex mb-3">
          <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
          <div>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height=".5rem"></Skeleton>
          </div>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
      </div>)
  }

  const [rowClick, setRowClick] = useState(true);
  const [selectedBen, setselectedBen] = useState('');

  const [formErrors, setFormErrors] = useState([]);
  const [loading, setLoading] = useState(false);


  /*
    STEP COMPONENTS
  */

  const SelectBeneficiary = () => {

    const handleSelect = (e) => {
      if (e.value !== null) {
        setselectedBen(e.value);
        setuserPolicyInput({ ...transactionData, selectedBen: e.value, });
      }
    };

    return (
      <>
        {(beneficiaries.length > 0) ?
          <>
            <DataTable
              value={beneficiaries}
              selectionMode={rowClick ? null : 'radiobutton'}
              selection={selectedBen}
              onSelectionChange={(e) => handleSelect(e)}
              dataKey="name"  >
              <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
              <Column field="name" ></Column>
            </DataTable>
          </> :
          <>   <h6>No beneficiaries found</h6> </>

        }
        <div className="text-center">
          <Button className='btn bg-primary me-2 mt-4' size='large' label={<><strong color='blue'>Continue</strong></>} severity="info" rounded outlined raised onClick={moveToNextView} />
        </div>
      </>)
  }


  const TransactionForm = () => {
    const sourceCurrencies = [{ value: 'GBP', label: 'GBP' }];
    const [destinationCurrencies, setDestinationCurrencies] = useState([]);


    const [fromCurrency, setFromCurrency] = useState("GBP");
    const [toCurrency, setToCurrency] = useState("USD");
    const [toCountry, setToCountry] = useState({
      value: "947",
      label: "Zimbabwe",
      countryCode: "ZW"
    });
    const [sourceOfFunds, setSourceOfFunds] = useState([{ value: "Business", label: "Business" }, { value: "Other", label: "Other" }]);
    const [purposes, setPurpose] = useState([]);
    const [destinationCountries, setDestinationCountries] = useState([]);
    const [amount, setAmount] = useState(20);
    const [remitterPay, setRemitterPay] = useState(0);
    const [receiveAmount, setReceiveAmount] = useState(24);
    const [rateLoading, setRateLoading] = useState(false);
    const [amountLoading, setAmountLoading] = useState(false);
    const [reverseCal, setReverseCal] = useState(false);
    const [formData, setFormData] = useState({
      purpose: '',
      amount: amount,
      source_of_income: 'Business',
      payment_method: paymentMethodsList[0].value,
      source_currency: fromCurrency,
      destination_currency: toCurrency,
      amount_type: 'SOURCE',
      trans_type: 'Cash Collection',
      collection_point_name: 'Harare',
      beneficiary_id: transactionData.selectedBen && transactionData.selectedBen.beneficiary_id,
      "username": localStorage.getItem('username'),
      "sessionToken": localStorage.getItem('sessionToken'),
      promotion_code: ""
    });
    const [output, setOutput] = useState(0);
    const [currentRate, setCurrentRate] = useState(1.19);
    const [totalCharges, setTotalCharges] = useState(0);
    const [displayFees, setDisplayFee] = useState(false);
    const [customPurpose, setCustomPurpose] = useState(false);

    const [receiveOptions, setReceiveOptions] = useState([
      { value: 'Cash Pickup', label: 'Cash Pickup' }
    ]);

    const [receiveOptionsData, setreceiveOptionsData] = useState({
      receiveOptions: receiveOptions,
      receiveOptionVal: null
    });


    const calculateOutput = async () => {
      try {
        if (!reverseCal) {
          setRateLoading(true)
        }

        const response = await TransactionService.getRates(toCountry.label, fromCurrency, toCurrency, amount, 'SOURCE');
        const CurrencyRate = response.body.result.rate;
        const output = response.body.result.destination_amount;

        setReceiveAmount(output)
        setOutput(output);
        setCurrentRate(CurrencyRate);
        setTotalCharges(response.body.result.total_charges)
        setRemitterPay(response.body.result.remitt_pay)
        setRateLoading(false)
        setAmountLoading(false)
      } catch (error) {
        console.log(error)
        setRateLoading(false)
        setAmountLoading(false)
      }
    };

    const reverseCalculate = async () => {
      try {
        if (receiveAmount > 2) {
          setReverseCal(true);
          setAmountLoading(true);

          setAmount((receiveAmount / currentRate).toFixed(2));

          const output = (amount * currentRate).toFixed(0);
          setOutput(output);

          setFormData({
            ...formData,
            'amount': (receiveAmount / currentRate).toFixed(2),
          });

        }
      } catch (error) {
        console.log(error)
      }
    };

    const getUiSetings = async () => {
      try {

        const response = await TransactionService.getUISettings(toCountry.value);

        const mappedPurposesData = response.responseBody.result.purposes.purpose.map(item => ({
          value: item,
          label: item
        }));

        const mappedSourcesData = response.responseBody.result.source_of_incomes.source_of_income.map(item => ({
          value: item,
          label: item
        }));

        mappedPurposesData.push({ value: "Other", label: "Other" });

        setPurpose(mappedPurposesData);
        setSourceOfFunds(mappedSourcesData);

        if (typeof response.responseBody.result.destination_currencies.currency === 'string') {
          setDestinationCurrencies([{ value: response.responseBody.result.destination_currencies.currency, label: response.responseBody.result.destination_currencies.currency }])
        } else {
          const mappedDestinationCurrencies = response.responseBody.result.destination_currencies.currency.map(item => ({
            value: item,
            label: item
          }));
          setDestinationCurrencies(mappedDestinationCurrencies)
        }

        if (typeof response.responseBody.result.transfer_types.transfer_type === 'string') {
          setReceiveOptions([response.responseBody.result.transfer_types.transfer_type])
        } else {
          setReceiveOptions(response.responseBody.result.transfer_types.transfer_type)
        }
      } catch (error) {
        console.log(error)
      }
    };

    const getDestinationCurrencies = async (countryToSendCurrencies) => {
      try {
        setRateLoading(true);
        const response = await TransactionService.getUISettings(countryToSendCurrencies);

        if (typeof response.responseBody.result.destination_currencies.currency === 'string') {

          setDestinationCurrencies([{ value: response.responseBody.result.destination_currencies.currency, label: response.responseBody.result.destination_currencies.currency }])

          // setToCurrency(destinationCurrencies[0])
          setRateLoading(false);
        } else {
          const mappedDestinationCurrencies = response.responseBody.result.destination_currencies.currency.map(item => ({
            value: item,
            label: item
          }));
          setDestinationCurrencies(mappedDestinationCurrencies)
          setRateLoading(false)
        }


      } catch (error) {
        console.log(error)
        setRateLoading(false);
      }
    };

    const getDestinationCountries = async () => {
      try {
        const response = await TransactionService.getDestinationCountries();

        const mappedDestinationCountries = response.responseBody.result.countries.country.map(item => ({
          value: item.id,
          label: item.name,
          countryCode: item.country_code
        }));

        setDestinationCountries(mappedDestinationCountries);

      } catch (error) {

      }
    }

    useEffect(() => {
      calculateOutput();
      getUiSetings();
      getDestinationCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromCurrency, toCurrency, amount]);


    const [paymentMethodListData, setpaymentMethodListData] = useState({
      paymentMethodList: paymentMethodsList,
      paymentMethodOptionVal: null
    });

    const handleChangeReceiveOption = (value, data) => {
      console.log(data)
      if (data && data.name) {
        setreceiveOptionsData({
          ...receiveOptionsData,
          'receiveOptionVal': value,
        });
        setDisplayFee(true)
      }
    };

    const handleChangePaymentmethod = (value, data) => {
      console.log(data)
      if (data && data.name) {
        setpaymentMethodListData({
          ...paymentMethodListData,
          'paymentMethodOptionVal': value,
        });

        setFormData({
          ...formData,
          'payment_method': value.value,
        });
        setDisplayFee(true)
      }
    };

    const handleChangePurpose = (value, data) => {
      console.log(data)
      if (data && data.name) {

        if (value.value === "Other") {

          setFormData({
            ...formData,
            'purpose': "",
          });
          setCustomPurpose(true);
        } else {
          setCustomPurpose(false);
          setFormData({
            ...formData,
            'purpose': value.value,
          });
        }

        setDisplayFee(true)
      }
    };

    const handleChangeSource = (value, data) => {
      console.log(data)
      if (data && data.name) {

        setFormData({
          ...formData,
          'source_of_income': value.value,
        });
        setDisplayFee(true)
      }
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      setDisplayFee(true)
    };

    const handleSubmit = async (e) => {

      if (formErrors && formErrors.length !== 0) {
        return "Validation failed";
      }
      setLoading(true);
      try {

        const response = await TransactionService.initiateTransaction(formData);
        console.log(response);
        setLoading(false);
        if (response.status === "SUCCESS") {
          console.log('Transaction creation successful');
          setuserPolicyInput({ ...transactionData, transactionDetails: formData, confirmDetails: response });
          moveToNextView();
        } else {
          toast.current.show({
            severity: 'error',
            summary: response.message,
            detail: response.message,
            life: 4000
          })
          console.error('Registration failed:', response.message);
        }
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Login Failed',
          detail: "Oops, We failed to communicate with our servers, Kindly check your internet connection" + error,
          life: 4000
        })
        console.error('Error:', error);
      }
    };

    const LoadingSkeleton = () => {
      return (
        <div className="border-round border-1 surface-border mt-1 surface-card">
          <Skeleton width="100%" height="50px"></Skeleton>
        </div>)
    }

    return (
      <>
        <Row className="align-items-center justify-content-center">
          <Col lg={7}>
            <div className="contact-box ">
              <div className="custom-form">
                <p id="error-msg" style={{ opacity: 10 }}> </p>
                <div id="simple-msg"></div>
                <form method="post" name="myForm">
                  <p id="error-msg" style={{ opacity: 1 }}> </p>
                  <div id="simple-msg"></div>
                  <div className="glassmorphic-container-curve-blue">
                    <div className="mt-2 mb-5 rounded-container">
                      <Row>
                        <Col>
                          <label className='mb-2 ' style={{ textAlign: "left", fontWeight: "100px", fontSize: '0.9em' }}>You send *</label>
                          <Row >
                            <Col lg={6}>
                              <div className="form-group mb-4">
                                {
                                  amountLoading ? <LoadingSkeleton /> :
                                    <CurrencyInput
                                      value={amount}
                                      onFocus={() => { setAmountLoading(false); setReverseCal(false) }}
                                      onValueChange={(amount) => {
                                        setFormData({
                                          ...formData,
                                          'amount': amount,
                                        });
                                        setAmount(amount)
                                      }}
                                      intlConfig={{ locale: "en-US", currency: fromCurrency }}
                                      allowDecimals={true}
                                      allowNegativeValue={false}
                                      className="form-control contact-form"
                                      style={{
                                        fontSize: '1.4em',
                                        lineHeight: '1.8em',
                                      }}
                                    />
                                }
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div
                                id="countryFlag"
                                className=" form-control contact-form"
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                <ReactCountryFlag
                                  className="emojiFlag"
                                  countryCode={fromCurrency.substring(0, 2)}
                                  style={{
                                    fontSize: '1.7em',
                                    lineHeight: '1em',
                                  }}
                                  aria-label={fromCurrency}
                                />
                                <div
                                  style={{ marginLeft: "10px", color: "black", width: "100%" }}
                                >
                                  <Select
                                    id="from"
                                    value={{ value: fromCurrency, label: fromCurrency }}
                                    onChange={(selectedOption) => setFromCurrency(selectedOption.value)}
                                    options={sourceCurrencies}
                                    styles={{
                                      control: provided => ({
                                        ...provided,
                                        border: 'none',
                                        fontSize: '1.3em',
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mb-1">
                            <label className='mb-2 ' style={{ textAlign: "left", fontWeight: "100px", fontSize: '0.9em' }}>They Receive</label>
                            <Col lg={4}>
                              <div className="form-group mb-4">
                                {rateLoading ? <LoadingSkeleton /> :
                                  <CurrencyInput
                                    value={receiveAmount}
                                    onValueChange={(receiveAmount) => { setRateLoading(false); setReceiveAmount(receiveAmount) }}
                                    onBlur={() => reverseCalculate()}
                                    intlConfig={{ locale: "en-US", currency: toCurrency }}
                                    allowDecimals={true}
                                    allowNegativeValue={false}
                                    className="form-control contact-form"
                                    style={{
                                      fontSize: '1.4em',
                                      lineHeight: '1.8em',
                                    }}
                                  />
                                }
                              </div>
                            </Col>
                            <Col lg={5}>
                              <div className="form-group">
                                <div
                                  id="countryFlag"
                                  className=" form-control contact-form"
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  <ReactCountryFlag
                                    className="emojiFlag"
                                    countryCode={toCountry.countryCode}
                                    style={{
                                      fontSize: '1em',
                                      lineHeight: '1em',
                                    }}
                                    aria-label={toCountry.countryCode}
                                  />

                                  <div
                                    style={{ marginLeft: "10px", color: "black", width: "100%" }}
                                  >
                                    <Select
                                      id="from"
                                      value={toCountry}
                                      onChange={(selectedOption) => {
                                        setToCountry(selectedOption);
                                        console.log(selectedOption)
                                        getDestinationCurrencies(selectedOption.value)
                                      }
                                      }
                                      options={destinationCountries}
                                      styles={{
                                        control: provided => ({
                                          ...provided,
                                          border: 'none',
                                          fontSize: '1.2em',
                                        }),
                                      }}
                                    />

                                  </div>
                                </div>
                              </div>
                            </Col>

                            <Col lg={3}>
                              {rateLoading ? <LoadingSkeleton /> :
                                <div className="form-group">
                                  <div
                                    id="countryFlag"
                                    className=" form-control contact-form"
                                    style={{ display: "flex", alignItems: "center" }}
                                  >
                                    <div
                                      style={{ marginLeft: "10px", color: "black", width: "100%" }}
                                    >

                                      <Select
                                        id="from"
                                        value={{ value: toCurrency, label: toCurrency }}
                                        onChange={(selectedOption) => setToCurrency(selectedOption.value)}
                                        options={destinationCurrencies}
                                        styles={{
                                          control: provided => ({
                                            ...provided,
                                            border: 'none',
                                            fontSize: '1.1em',
                                          }),
                                        }}
                                      />


                                    </div>
                                  </div>
                                </div>
                              }
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Col md={5}>
                              <label className='mb-2 ' style={{ textAlign: "left", fontWeight: "100px", fontSize: '0.9em' }}>Source Of funds</label>
                            </Col>
                            <Col lg={12}>
                              <div className="form-group">
                                <Select
                                  styles={{
                                    control: provided => ({
                                      ...provided,
                                      border: 'none',  // Set border to none
                                    }),
                                  }}
                                  className="form-control contact-form"
                                  name='sourceOfFundsDataVal'
                                  isSearchable={true}
                                  options={sourceOfFunds}
                                  // value={sourceOfFunds.value}
                                  value={{ value: formData.source_of_income, label: formData.source_of_income }}

                                  onChange={handleChangeSource}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Col md={5}>
                              <label className='mb-2 ' style={{ textAlign: "left", fontWeight: "100px", fontSize: '0.9em' }}>Purpose Of funds</label>
                            </Col>
                            <Col lg={12}>
                              <div className="form-group">
                                <Select
                                  styles={{
                                    control: provided => ({
                                      ...provided,
                                      border: 'none',  // Set border to none
                                    }),
                                  }}
                                  className="form-control contact-form"
                                  name='purposeDataVal'
                                  isSearchable={true}
                                  options={purposes}
                                  value={purposes.value}
                                  onChange={handleChangePurpose}
                                />
                              </div>
                              {
                                customPurpose ? <div className="form-group">
                                  <input name="purpose" id="purpose" type="text"
                                    value={formData.purpose}
                                    onChange={handleChange}
                                    required
                                    className="mt-3 form-control contact-form input-custom-style" placeholder="Please specify purpose" />
                                  {formErrors.purpose && <p className="text-danger">{formErrors.purpose}</p>}
                                </div> : <></>
                              }



                            </Col>
                          </Row>



                          <Row className="mb-4">
                            <Col md={5}>
                              <label className='mb-2 ' style={{ textAlign: "left", fontWeight: "100px", fontSize: '0.9em' }}>Select Payment Method</label>
                            </Col>
                            <Col lg={12}>
                              <div className="form-group">
                                <Select
                                  styles={{
                                    control: provided => ({
                                      ...provided,
                                      border: 'none',  // Set border to none
                                    }),
                                  }}
                                  className="form-control contact-form"
                                  name='paymentMethodOptionVal'
                                  isSearchable={true}
                                  options={paymentMethodListData.paymentMethodList}
                                  // value={paymentMethodListData.value}
                                  value={{ value: paymentMethodsList[0].value, label: paymentMethodsList[0].label }}
                                  onChange={handleChangePaymentmethod}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="mb-4">
                            <Col md={5}>
                              <label className='mt-2 ' style={{ textAlign: "left", fontWeight: "100px", fontSize: '0.9em' }}>Receive Method</label>
                            </Col>
                            <Col >
                              <div className="form-group">
                                <Select
                                  styles={{
                                    control: provided => ({
                                      ...provided,
                                      border: 'none',  // Set border to none
                                    }),
                                  }}
                                  className="form-control contact-form"
                                  name='receiveOptionVal'
                                  isSearchable={true}
                                  options={receiveOptionsData.receiveOptions}
                                  value={receiveOptionsData.value}
                                  onChange={handleChangeReceiveOption}
                                />
                              </div>
                            </Col>

                          </Row>

                          <Row className="mb-4">
                            <Col md={5}>
                              <label className='mt-4' style={{ textAlign: "left", fontWeight: "100px", fontSize: '0.9em' }}>Promotion Code</label>
                            </Col>
                            <Col >
                              <div className="form-group">
                                <input name="promotion_code" id="promotion_code" type="text"
                                  value={formData.promotion_code}
                                  onChange={handleChange}
                                  className="mt-3 form-control contact-form input-custom-style" placeholder="Enter promo code" />
                              </div>
                            </Col>

                          </Row>



                          {displayFees ?
                            <div>
                              <Row className="mb-1">
                                <Col className="con-title">Fee </Col><Col className="con-value">{totalCharges} {fromCurrency}</Col>
                              </Row>
                              <Row className="mb-1">
                                <Col className="con-title">Total to pay </Col><Col className="con-value">{remitterPay} {fromCurrency}</Col>
                              </Row>
                              <Row className="mb-1">
                                <Col className="con-title">Transfer Time </Col><Col className="con-value " style={{ color: 'green', fontStyle: "italic" }}>Instant</Col>
                              </Row>
                              <Row className="mb-1">
                                <Col className="con-title">Collection Points </Col><Col className="con-value " style={{ color: 'green', fontSize: '14px' }}>Wiremit and Clickacash Branches</Col>
                              </Row>
                            </div> : <></>
                          }
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <div className="bottom-div">
                          <p>Exchange Rate</p>
                          <p className="rate-font">1 {fromCurrency} = {currentRate} {toCurrency}</p>
                        </div>

                      </Row>


                    </div>
                  </div>


                </form>
              </div>
            </div>
          </Col>
        </Row>
        {!rateLoading && !amountLoading ?
          <div className="text-center">
            <Button className="btn bg-primary me-2 mt-4" size="large" label={<strong color="blue">Continue</strong>} severity="info" rounded outlined raised onClick={handleSubmit} />
          </div> : <></>
        }

      </>
    );
  };


  const ConfirmationSection = () => {

    const handleSubmit = async (e) => {
      let user = JSON.parse(localStorage.getItem('user'));
      const formData = {
        "username": localStorage.getItem('username'),
        "sessionToken": localStorage.getItem('sessionToken'),
        "senderName": user.firstname,
        "senderEmail": user.email,
        "senderPhoneNumber": user.mobile,
        "trans_session_id": transactionData.confirmDetails && transactionData.confirmDetails.responseBody ? transactionData.confirmDetails.responseBody.result.transaction.trans_session_id : "Not provided"
      }


      if (formErrors && formErrors.length !== 0) {
        return "Validation failed";
      }
      setLoading(true);
      try {
        const response = await TransactionService.confirmTransaction(formData);
        console.log(response);
        localStorage.setItem('transactionProcess', JSON.stringify(response.responseBody.transactionConfirmation.result.transaction))
        setLoading(false);
        if (response.status === "SUCCESS") {
          console.log('Transaction creation successful');
          setuserPolicyInput({ ...transactionData, finalResponse: response.responseBody });
          moveToNextView();
        } else {
          toast.current.show({
            severity: 'error',
            summary: 'Login Failed',
            detail: response.message,
            life: 4000
          })
          console.error('Registration failed:', response.message);
        }
      } catch (error) {
        toast.current.show({
          severity: 'error',
          summary: 'Login Failed',
          detail: "Oops, We failed to communicate with our servers, Kindly check your internet connection" + error,
          life: 4000
        })
        console.error('Error:', error);
      }
    };

    return (
      <>
        <Row className="align-items-center justify-content-center">
          {(transactionData)
            ? <>
              <ul className='mt-1'>
                <li className='row'>
                  <div className='col-md-4'>
                    <h6>Selected Receiving Beneficiary</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <h6>{transactionData.selectedBen ? transactionData.selectedBen.name : "Not provided"}</h6>
                  </div>
                </li>
                <li className='row'>
                  <div className='col-md-4'>
                    <h6>Amount</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <h6>{transactionData.transactionDetails ? parseFloat(transactionData.transactionDetails.amount).toFixed(2) : "Not provided"}</h6>
                  </div>
                </li>
                <li className='row'>
                  <div className='col-md-4'>
                    <h6>Source currency | Destination currency</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <h6>{transactionData.transactionDetails ? <>{transactionData.transactionDetails.source_currency} | {transactionData.transactionDetails.destination_currency}</> : "Not provided"}</h6>
                  </div>
                </li>
                <li className='row'>
                  <div className='col-md-4'>
                    <h6>Purpose of funds</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <h6>{transactionData.transactionDetails ? transactionData.transactionDetails.purpose : "Not provided"}</h6>
                  </div>
                </li>

                <li className='row'>
                  <div className='col-md-4'>
                    <h6>Source of funds</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <h6>{transactionData.transactionDetails ? transactionData.transactionDetails.source_of_income : "Not provided"}</h6>
                  </div>
                </li>

                <li className='row'>
                  <div className='col-md-4'>
                    <h6>Total Amount</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <h6>{transactionData.confirmDetails && transactionData.confirmDetails.responseBody ? transactionData.confirmDetails.responseBody.result.transaction.remitter_pay : "Not provided"}</h6>
                  </div>
                </li>
                <li className='row'>
                  <div className='col-md-4'>
                    <h6>Charges</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <h6>{transactionData.confirmDetails && transactionData.confirmDetails.responseBody ? parseFloat(transactionData.confirmDetails.responseBody.result.transaction.total_charges).toFixed(2) : "Not provided"}</h6>
                  </div>
                </li>
                <li className='row'>
                  <div className='col-md-4'>
                    <h6>Total Receivable Amount</h6>
                  </div>
                  <div className="text-right col-md-6">
                    <h6>{transactionData.confirmDetails && transactionData.confirmDetails.responseBody ? transactionData.confirmDetails.responseBody.result.transaction.receive_amount : "Not provided"}</h6>
                  </div>
                </li>

              </ul>
            </>
            : <> No informaiton to display</>}
        </Row>
        <div className="text-center">
          <Button className='btn   me-2 mt-4' size='large' label={<><strong color='white'>Cancel Transaction</strong></>} severity="info" rounded outlined raised onClick={(e) => { window.location.reload() }} />
          <Button className='btn bg-primary me-2 mt-4' size='large' label={<><strong color='blue'>Confirm Transaction</strong></>} severity="info" rounded outlined raised onClick={handleSubmit} />
        </div>
      </>)
  }

  const PaymentSection = () => {

    const [value, setValue] = useState(0);
   

    const toast = useRef(null);
    const interval = useRef(null);

    var transactionRef = JSON.parse(localStorage.getItem('transactionProcess')).trans_ref;


    useEffect( () => {
      let _val = value;
      var message = "TRANSACTION PENDING";

      interval.current = setInterval(() => {
       
        _val += Math.floor(Math.random() * 10) + 1;

        if (_val >= 100) {
          _val = 100;
          toast.current.show({ severity: 'info', summary: "Transaction Status", detail: message });
          clearInterval(interval.current);
        }else{
          TransactionService.checkTransactionStatus(transactionRef).then((response)=>{

            var transactionStatus="PENDING_CLEARANCE";
            if(response!==undefined){
              var transactionStatus = response.status;
            }

           if(transactionStatus==="PENDING_CLEARANCE" || response.status==undefined || transactionStatus===undefined|| transactionStatus=="" ){
             _val -=2;
             setValue(_val);
           }else{
             _val = 100;
             setValue(_val);
             message = response.message!==undefined?transactionStatus + ". "+ response.message: transactionStatus + ".";
           }
          });
         
        }

      }, 2000);

      return () => {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      };
    }, []);



    const handleChange = (e, fieldName) => {
      console.log(fieldName);
      if (e.value != null) {
        setuserPolicyInput({
          ...transactionData,
          [fieldName]: e.value,
        });
      }

    };

    return (
      <>
        <Row className="align-items-center justify-content-center">
          {(transactionData.finalResponse.payment && transactionData.finalResponse.payment.result)
            ? <>
            <div className="mt-1 inline-flex align-items-center">
                  <Toast ref={toast}></Toast>
                  <ProgressBar value={"Checking transaction status: "+value}></ProgressBar>
                </div>

              <div className="text-center mt-4 mb-4">
                
                <Divider align="left">
                  <div className="inline-flex align-items-center">
                    <i className="pi pi-user mr-2"></i>
                    <b>Complete your payment via your bank account.</b>
                  </div>
                </Divider>
              </div>
              {/* <iframe style={{ height: "40em" }} class="bdt-lazyload lazy-loaded" data-throttle="300" data-threshold="100" data-live="true" data-src="https://currency.wiremit.money/" data-auto_height="false" allowfullscreen="" src={transactionData.finalResponse.payment.result.link}></iframe> */}
              <div className="ms-auto inline-flex text-center">
                <Link target='_blank' to={transactionData.finalResponse.payment.result.link} className="btn bg-primary inline-flex align-items-center">Click to complete transaction from your bank</Link>
              </div>
              <div className="text-center mt-4">
                <Divider align="left">
                  <div className="inline-flex align-items-center">
                    <i className="pi pi-user mr-2"></i>
                    <b>Or Scan QR Code to complete transaction on your banking app.</b>
                  </div>
                </Divider>
                <img src={transactionData.finalResponse.payment.result.qrCodeData} style={{ width: '20%' }} alt='' />
              </div>

            </>
            : <> No informaiton to display</>}
        </Row>

      </>)
  }
  const [activeIndex, setActiveIndex] = useState(0);

  const toast = useRef(null);
  const items = [
    {
      label: 'Select Beneficiary'
    },
    {
      label: 'Enter transaction details',
    },
    {
      label: 'Confirm transaction',
    },
    {
      label: 'Make a Secure Payment',
    },
  ];
  const [beneficiaries, setBeneficiaries] = useState([]);

  useEffect(() => {

    BeneficiaryServices.getBeneficiary().then((data) => {
      try {
        if (data.responseBody.result.beneficiaries.beneficiary.length === undefined) {
          console.log([data.responseBody.result.beneficiaries.beneficiary])
          var singleBeneficiary = [data.responseBody.result.beneficiaries.beneficiary];
          setBeneficiaries(singleBeneficiary);
        } else {

          setBeneficiaries(data.responseBody.result.beneficiaries.beneficiary);
        }
      } catch (error) {
        console.log(error)
      }

    });
  }, []);

  const [activeView, setactiveView] = useState(0);
  const [transactionData, setuserPolicyInput] = useState({});
  const viewList = [
    <SelectBeneficiary />,
    <TransactionForm />,
    <ConfirmationSection />,
    <PaymentSection />
  ];
  const viewListTitles = [
    { "topHeading": "Fill the send money form", "middleHeading": "Select Receiving Beneficiary" },
    { "topHeading": "Fill the send money form", "middleHeading": "Enter transaction details" },
    { "topHeading": "Fill the send money form", "middleHeading": "Confirm details" },
    { "topHeading": "", "middleHeading": "Make a Secure Payment" },
  ];

  const moveToNextView = () => {

    console.log(transactionData)
    setactiveView(activeView + 1);
    if (activeView < viewList.length) setactiveView(activeView + 1);
    setActiveIndex(activeView + 1)
  }

  const moveToPreviousView = () => {
    if (activeView !== 0) setactiveView(activeView - 1);
    setActiveIndex(activeView - 1)
  }


  return (
    <React.Fragment>
      <div className="section " id="home">
        <Container>
          <Row className="align-items-center justify-content-between">
            <div className="">
              <Toast ref={toast}></Toast>
              <Steps model={items} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false} />

              {React.cloneElement(<TitleComponent />, {
                titles: viewListTitles[activeIndex],
                moveToPreviousView: moveToPreviousView,
                activeView: activeIndex,
                transactionData: transactionData
              })}
              {loading ? (<div className="border-round border-1 surface-border p-4 surface-card">
                <div className="flex mb-3">
                  <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                  <div>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                    <Skeleton height=".5rem"></Skeleton>
                  </div>
                </div>
                <Skeleton width="100%" height="150px"></Skeleton>
                <div className="flex justify-content-between mt-3">
                  <Skeleton width="4rem" height="2rem"></Skeleton>
                  <Skeleton width="4rem" height="2rem"></Skeleton>
                </div>
              </div>) : <>{React.cloneElement(viewList[activeIndex], {
                titles: viewListTitles[activeIndex],
                moveToNextView: moveToNextView,
                setuserPolicyInput: setuserPolicyInput,
                transactionData: transactionData,
                toast: toast
              })}</>}
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Section;
