import React from 'react'
import Navbar1 from '../../layout/Navbar';
import Register from './register';
import NavBar from '../../components/NavBarLanding';

const signup = () => {
    return (
        <React.Fragment>
            <NavBar page="signup"/>
            <Register />
        </React.Fragment>
    )
}

export default signup;