import React from 'react'
import NavbarInternal from '../../layout/NavbarInternal';
import Section from './section';

const sendMoney = () => {
    return (
        <React.Fragment>
            <NavbarInternal />
            <Section />
        </React.Fragment>
    )
}

export default sendMoney;