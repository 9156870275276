
/* eslint-disable jsx-a11y/alt-text */

/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
// import images
import home1 from "../../assets/images/home-header-1.png"
import { useMountEffect } from 'primereact/hooks';
import { Badge } from 'primereact/badge';
import BeneficiaryServices from '../../services/beneficiaryServices';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';


import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dialog } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import PhoneInput from "react-phone-input-2";
import ReactCountryFlag from "react-country-flag";
import Select from "react-select";
import countryList from "react-select-country-list";
import TransactionService from '../../services/transactionServices';
import { render } from 'react-dom';
import VideoRecorder from 'react-video-recorder';
import { Typography } from '@mui/material';
import AuthService from '../../services/authServices';
import VideoPlayer from './VideoPlayer';
import { FaEye } from 'react-icons/fa';
import { CiEdit } from "react-icons/ci";
import { Co } from 'react-flags-select';


AOS.init({
  duration: 1800,
});

const Section = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [uploadKycVideo, setUploadKycVideo] = useState(true);
  const [base64String, setBase64String] = useState('');
  const [base64PoaString, setBase64PoaString] = useState('');
  const [message, setMessage] = useState('Uploading...🔄 ');
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [isNewBenDialogvisible, setIsNewBenDialogvisible] = useState(false);

  const [isProofOfAddress, setIsProofOfAddress] = useState(false);

  const [isEditBenDialogvisible, setIsEditBenDialogvisible] = useState(false);
  
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(false);
  const [displayUploadedVideo, setDisplayUploadedVideo] = useState(false);
  const optionsCountries = countryList().getData();
  const [collectionPoints, setCollectionPoints] = useState([]);
  const [selectedCollectionPoints, setSelectedCollectionPoints] = useState({});
  const [displayStatement, setStatementDisplay] = useState(false);
  const [countryData, setCountryData] = useState({
    optionsCountries: optionsCountries,
    country: "",
    region: "",
    countryVal: null
  });

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
  const paginatorRight = <Button type="button" icon="pi pi-download" text />;


  useMountEffect(() => {
    const fetchUserRole = async () => {
      try {
        setUser(JSON.parse(localStorage.getItem('user')));
        if(JSON.parse(localStorage.getItem('user')).status!=="valid" ){
          setIsProofOfAddress(true);
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };
    fetchUserRole();
    getCollectionPoints();

  });

  const actionTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button type="button" icon="pi pi-search" rounded></Button>
        <Button type="button" icon="pi pi-pencil" severity="success" rounded></Button>
      </div>
    );
  };

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }


  const handleChangeCountry = (value, data) => {
    console.log(data)
    if (data && data.name) {
      setCountryData({
        ...countryData,
        'countryVal': value,
      });
      setFormData({
        ...formData,
        'country': value.label
      });
    }
  };

  const getCollectionPoints = async (e) => {

    try {
      const response = await BeneficiaryServices.getCollectionPoints();

      if (response.status === "SUCCESS") {
        console.log(response.responseBody.result.collection_points.collection_point);
        setCollectionPoints(response.responseBody.result.collection_points.collection_point);

      } else {
        // toast.current.show({
        //   severity: 'success',
        //   summary: 'You might not be able to send money: ' + response.message,
        //   detail: response.data.message,
        //   life: 4000
        // })
        console.error('Getting Collection:', response.message);
      }

    } catch (error) {

      // toast.current.show({
      //   severity: 'error',
      //   summary: 'Getting Collection Points Failed',
      //   detail: "Oops, We failed to communicate with our servers, Kindly check your internet connection" + error,
      //   life: 4000
      // });

      console.error('Error:', error);
    }
  };

  const collectionPointsOptions = collectionPoints && collectionPoints ? Object.values(collectionPoints).map((point, index) => ({
    value: point,
    label: point.name + " - "+point.city,
  }))
    : [];

  const handleCollectionPoints = (value, data) => {

    setSelectedCollectionPoints(value);
    if (data && data.name) {
      setFormData({
        ...formData,
          "collection_point_id": value.value.collection_id,
          "collection_point_name": value.value.name,
          "collection_point_code": value.value.code,
          "collection_point_proc_bank": value.value.bank,
          "collection_point_address": value.value.address,
          "collection_point_city": value.value.city,
          "collection_pin": "",
          "collection_point_bank": value.value.bank,
          "collection_point": value.value.name,
          countryId:value.value.country_id


      });
    }

    console.log(formData);
  };

  const getStatement = async () => {
    await TransactionService.getUserTransactions().then((data) => {

      try {

        if (data.responseBody.result.transactions.transaction.length === undefined) {
          var singleBeneficiary = [data.responseBody.result.transactions.transaction];
          setTransactions(singleBeneficiary);
        } else {
          setTransactions(data.responseBody.result.transactions.transaction);
        }
      } catch (error) {
        console.log(error)
      }

    });
  }

  const uploadPOA = async (e) => {
    e.preventDefault();

    setIsSkeletonLoading(true);
    await AuthService.uploadPOA(base64PoaString).then((data) => {
      console.log(data);
      try {
        if (data.status === "SUCCESS") {
          setIsSkeletonLoading(false);
          setBase64PoaString('');
          setIsProofOfAddress(false);
          toast.current.show({
            severity: 'success',
            summary: 'POA uploaded successfuly ',
            detail: data.message,
            life: 5000
          })
        }else{
          toast.current.show({
            severity: 'error',
            summary: 'Upload could not be completed',
            detail: data.message,
            life: 5000
          });
          setIsSkeletonLoading(false);
        }
      } catch (error) {
        setIsSkeletonLoading(false);
        toast.current.show({
          severity: 'error',
          summary: 'Upload could not complete. Try again in a moment.',
          life: 5000
        });
        console.log(error)
      }

    });
  }

  const uploadKyc = async () => {
    await AuthService.uploadKyc(base64String).then((data) => {
      try {
        if (data.status === "SUCCESS") {
          toast.current.show({
            severity: 'success',
            summary: 'Video uploaded successfuly ',
            detail: data.result.kyc_video,
            life: 5000
          })
          setIsSkeletonLoading(false);
          setUploadKycVideo(false);
          setBase64String('');
          getKyc();
        }
      } catch (error) {
        console.log(error)
      }

    });
  }

  const getKyc = async () => {
    setIsSkeletonLoading(true);
    setMessage('Retrieving your uploaded video. This might take a while...')
    await AuthService.getKyc().then((data) => {
      try {
        if (data.status === "SUCCESS") {
          setBase64String(data.result.kyc_video.data);
          setIsSkeletonLoading(false);
          setDisplayUploadedVideo(true);
        }
      } catch (error) {
        console.log(error)
      }
    });
  }

  useEffect(() => {

    getStatement();

    BeneficiaryServices.getBeneficiary().then((data) => {

      try {
        if (data.responseBody.result.beneficiaries.beneficiary.length === undefined) {
          var singleBeneficiary = [data.responseBody.result.beneficiaries.beneficiary];
          setBeneficiaries(singleBeneficiary);
          setFilteredBeneficiaries(singleBeneficiary)
        } else {
          setBeneficiaries(data.responseBody.result.beneficiaries.beneficiary);
          setFilteredBeneficiaries(data.responseBody.result.beneficiaries.beneficiary)
        }
      } catch (error) {
        console.log(error)
      }

    });
  }, []);

  const toast = useRef(null);

  const [formErrors, setFormErrors] = useState({
    fname: '',
    lname: '',
    address1: '',
    mobile: '',
    email: ''
  });

  const validateForm = () => {
    let valid = true;
    const errors = {};

    // Validate First Name
    if (!formData.fname.trim()) {
      errors.fname = 'First Name is required';
      valid = false;
    }

    // Validate Last Name
    if (!formData.lname.trim()) {
      errors.lname = 'Last Name is required';
      valid = false;
    }

    // Validate Address
    if (!formData.address1.trim()) {
      errors.address1 = 'Address is required';
      valid = false;
    }

    // Validate City
    if (!formData.city.trim()) {
      errors.city = 'City is required';
      valid = false;
    }

    // Validate Mobile
    if (!formData.mobile.trim()) {
      errors.mobile = 'Mobile number is required';
      valid = false;
    }

    // Validate Email
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Invalid email address';
      valid = false;
    }

    // Set form errors
    setFormErrors(errors);

    return valid;
  };


  const [formData, setFormData] = useState({
    username: "",
    password: "",
    "fname": "",
    "lname": "",
    "address1": "",
    "city": "",
    "countryId": "947",
    "mobile": "",
    "email": "",
    "collection_point": "",
    "collection_point_id": "",
    "collection_point_bank": "",
    "collection_point_code": "",
    "collection_point_address": "",
    "collection_point_city": "",
    "collection_point_state": "",
    "collection_pin": "",
    "collection_point_proc_bank": "", 
    


    // "collection_point_id": "242", 
    // "delivery_bank": "180",
    // "collection_point_name": "Wiremit (Joina City)", 
    // "collection_point_code": "0101", 
    // "collection_point_address": "Corner of Jason Moyo & Julius Nyerere", 
    // "collection_point_city": "Harare"

  });


  const handleSelectPoa = (e) => {

    const file = e.target.files[0];

    console.log(e.target.files);

    const reader = new FileReader();

    reader.onload = () => {
      const base64 = reader.result.split(',')[1]; 
      setBase64PoaString(base64);
    };

    // Read the file as base64
    reader.readAsDataURL(file);

  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!value.trim()) {
      setFormErrors({
        ...formErrors,
        [name]: name + ' is required'
      });
    } else {
      setFormErrors({
        ...formErrors,
        [name]: ''
      });
    }


    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleChangePhone = (value, data) => {
    if (data && data.name) {
      setFormData({
        ...formData,
        'mobile': value,
      });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();


    if (!isValid) {
      toast.current.show({
        severity: 'error',
        summary: 'Invalid Request',

        detail: (
          <div>
            {formErrors.fname && <div>{formErrors.fname}</div>}
            {formErrors.lname && <div>{formErrors.lname}</div>}
            {formErrors.address1 && <div>{formErrors.address1}</div>}
            {formErrors.mobile && <div>{formErrors.mobile}</div>}
            {formErrors.email && <div>{formErrors.email}</div>}
          </div>
        ),
        life: 4000
      });
      return;
    }

    setIsSkeletonLoading(true)
    try {

      const response = await BeneficiaryServices.addNewBeneficiary(formData);

      setIsSkeletonLoading(false);
      if (response.data.status === "SUCCESS") {
        toast.current.show({
          severity: 'success',
          summary: 'New Beneficiary was added successfully',
          detail: response.data.message,
          life: 4000
        })
        window.location.reload();
        setIsNewBenDialogvisible(false);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed to save beneficiary',
          detail: response.message,
          life: 4000
        })
        console.error('Registration failed:', response.message);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed to save beneficiary',
        detail: error.message,
        life: 4000
      })
      console.error('Error:', error);
    }
  };

// Handle Edit beneciairy
  const [formEditData, setEditFormData] = useState({
      "beneficiary_id": "",
      "name": "",
      "fname": "",
      "mname": "",
      "nickname": "",
      "telephone": "",
      // eslint-disable-next-line no-dupe-keys
      "fname": "",
      "lname": "",
      "address1": "",
      "city": "",
      "countryId": "947",
      "mobile": "",
      "email": ""
  });

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    setEditFormData({
      ...formEditData,
      [name]: value
    });
  };

  const handleEditChangePhone = (value, data) => {
    if (data && data.name) {
      setEditFormData({
        ...formEditData,
        'mobile': value,
      });
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();

    setIsSkeletonLoading(true)
    try {
      const response = await BeneficiaryServices.editBeneficiary(formEditData);

      setIsSkeletonLoading(false);
      if (response.data.status === "SUCCESS") {
        toast.current.show({
          severity: 'success',
          summary: 'Beneficiary updated successfully',
          detail: response.data.message,
          life: 4000
        })
        window.location.reload();
        setIsNewBenDialogvisible(false);
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Failed to update beneficiary',
          detail: response.message,
          life: 4000
        })
        console.error('Registration failed:', response.message);
      }
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Failed to update beneficiary',
        detail: error.message,
        life: 4000
      })
      console.error('Error:', error);
    }
  };

  const handleSearch = (query) => {
    const filteredBeneficiaries = beneficiaries.filter((beneficiary) =>
      beneficiary.name.toLowerCase().includes(query.toLowerCase()) ||
      beneficiary.email.toLowerCase().includes(query.toLowerCase()) ||
      beneficiary.mobile.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredBeneficiaries(filteredBeneficiaries);
  };

  const LoadingSkeleton = () => {
    return (
      <div className="border-round border-1 surface-border p-4 surface-card">
        <div className="flex mb-3">
          <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
          <div>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height=".5rem"></Skeleton>
          </div>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
      </div>)
  }

  const LoadingSkeletonVideo = () => {
    return (
      <div className="border-round border-1 surface-border p-4 surface-card">
        <Skeleton width="100%" height="350px"></Skeleton>
      </div>)
  }

  const LoadingSkeletonPoa = () => {
    return (
      <div className="border-round border-1 surface-border p-4 surface-card">
        <Skeleton width="100%" height="100px"></Skeleton>
      </div>)
  }

  return (
    <React.Fragment>
      <div className="section home-3" id="home">
        <Toast ref={toast} />
        <div className="container">
          <Row className="align-items-center justify-content-between">
            <Col md={6}>
              <h3 className="home-title"><span style={{ color: "#268e9c", fontWeight: "400", fontSize: "2em" }}>Hello </span><span style={{ color: "#2e3664", fontWeight: "600", fontSize: "2em" }}>{user.firstname}</span></h3>
              <Row>
                <Col md={4} className='mt-2'>
                <Badge value={user.status==="valid"?"Profile Status:  ACTIVE":"Profile Status: INACTIVE"} severity= {user.status==="valid"?"info":"danger"}></Badge >
                </Col>
               
                <Col md={1}></Col>
                <Col md={7}>
                {
                user.status!=="valid"?<> 

                <Button onClick={() => setIsProofOfAddress(true)} className='bg-primary glowButton' size='small' label={<><strong color='white'> Upload proof of address</strong></>} severity="info" rounded outlined />

                </>:<></>
              }
                </Col>
             
              
              </Row>
              
            </Col>
            
           
          </Row>
          <div className="row mt-5">
            <div className="col-xxl-5 col-xl-5 col-lg-12  ">
              <div className=' p-0' header={(<img src={home1} style={{ width: '100%' }} alt="home" />)}>
                <div className='m-2 flex justify-content-center'>
                  <img src={home1} style={{ width: '80%' }} alt="home" />
                  <h3 className='text-left '>Send Money now!</h3>
                  <p><strong>The fast and secure way</strong><br />Send from your bank account to anywhere.</p>

                  {
                    user.status === 'valid' ?
                      <>
                        <Button className='btn bg-primary me-2 mt-4' size='large' label={<><strong color='blue'>Send Money Now</strong></>} severity="info" rounded outlined raised
                          onClick={() => { navigate("/send-money") }}
                        />
                        {
                          displayStatement ?
                            <Button className='btn bg-primary me-2 mt-4' size='large' label={<><strong color='blue'>View Beneficiaries</strong></>} severity="info" rounded outlined raised
                              onClick={() => setStatementDisplay(false)}
                            />
                            :
                            <Button className='btn bg-primary me-2 mt-4' size='large' label={<><strong color='blue'>View Account Statement</strong></>} severity="info" rounded outlined raised
                              onClick={() => setStatementDisplay(true)}
                            />
                        }
                      </> : <></>
                  }

                </div>
              </div>
            </div>
            <div className="col-xxl-1 col-xl-1 col-lg-12  ">
              <Divider layout="vertical" />
            </div>


            {
              user.status === 'valid' || user.status !== 'valid' ?
                <>
                  {displayStatement ?
                    <div className="col-xxl-23 col-xl-6 col-lg-12">
                      <DataTable
                        className='custom-container'
                        value={transactions}
                        paginator rows={5}
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}"
                        paginatorLeft={paginatorLeft}
                        paginatorRight={paginatorRight}
                      >
                        <Column field="trans_ref" header="Ref" ></Column>
                        <Column field="benef_name" header="Receiver" ></Column>
                        <Column field="dest_currency" header="Currency" ></Column>
                        <Column field="dest_amount" header="Amount" ></Column>
                        <Column field="status" header="Status" ></Column>
                        <Column field="trans_type" header="Type" ></Column>
                        <Column field="creation_date" header="Date" ></Column>
                      </DataTable>
                    </div> :
                    <div className="col-xxl-5 col-xl-5 col-lg-12  ">
                      <h3 className="home-title"><span style={{ color: "#2e3664", fontWeight: "400" }}>Your</span> <span style={{ color: "#268e9c", fontWeight: "600" }}> beneficiaries</span></h3>
                      <div className="home-heading">
                        <p className="">We remember your <span style={{ color: "#268e9c", fontWeight: "400" }}>friends and family</span>, so you can easily send money to them. </p>
                      </div>

                      <Button onClick={() => setIsNewBenDialogvisible(true)} className='btn bg-sucess me-2 mb-4' size='large' label={<><strong color='white'>Add new beneficiary</strong></>} severity="info" rounded outlined raised />

                      {(beneficiaries.length > 0) ?
                        <>
                          <div className="col-md-6">
                            <input
                              type="text"
                              placeholder="Search beneficiary"
                              className=" form-control contact-form input-custom-style mb-4"
                              onChange={(e) => handleSearch(e.target.value)}
                            />

                          </div>

                          <div className=" ">
                            <Accordion activeIndex={0}>
                              {filteredBeneficiaries.map((beneficiary, index) => (
                                <AccordionTab
                                  key={index}
                                  header={
                                    <div className="flex align-items-center">
                                      <i className="pi pi-search mr-2"></i>
                                      <span className="vertical-align-middle">{beneficiary.name}</span>
                                      <i className="pi pi-user ml-2 ml-2"></i>
                                    </div>
                                  }
                                >
                                  <ul className='mt-1'>
                                    <li className='row'>
                                      <div className='col-md-4'>
                                        <h6>Phone Number</h6>
                                      </div>
                                      <div className="text-right col-md-6">
                                        <h6>{beneficiary.mobile ? beneficiary.mobile : "Not provided"}</h6>
                                      </div>
                                    </li>
                                    <li className='row'>
                                      <div className='col-md-4'>
                                        <h6>Email Address</h6>
                                      </div>
                                      <div className="text-right col-md-6">
                                        <h6>{beneficiary.email ? beneficiary.email : "Not provided"}</h6>
                                      </div>
                                    </li>
                                    <li className='row'>
                                      <div className='col-md-4'>
                                        <h6>Country</h6>
                                      </div>
                                      <div className="text-right col-md-6">
                                        <h6>{beneficiary.country ? beneficiary.country : "Not provided"}</h6>
                                      </div>
                                    </li>

                                    <Button 
                                        className='btn'
                                       
                                        rounded 
                                        aria-label="Filter"
                                        onClick={() => {
                                          setIsEditBenDialogvisible(true)
                                          setEditFormData(beneficiary)
                                        }}
                                      >
                                      Edit
                                    </Button>

                                  </ul>
                                  
                                </AccordionTab>
                              ))})
                            </Accordion>

                          </div>
                        </> :
                        <>   <h6>No beneficiaries found</h6> </>
                      }
                    </div>}
                </> :
                // video upload
                <div className="col-xxl-6 col-xl-6 col-lg-12 custom-container justify-content-center">

                  {isSkeletonLoading ?
                    <>
                      <h3 className="home-title mb-2"><span style={{ color: "#268e9c", fontWeight: "100", fontSize: "0.7em" }}>{message}</span></h3>
                      <LoadingSkeletonVideo />
                    </>
                    :

                    !displayUploadedVideo ?
                      <>
                        <h3 className="home-title mb-2"><span style={{ color: "#268e9c", fontWeight: "100", fontSize: "0.7em" }}>Record video for KYC verification. </span><span style={{ color: "#2e3664", fontWeight: "100", fontSize: "0.7em" }}>Remember to smile {user.firstname} 😊</span></h3>
                        <VideoRecorder
                          constraints={{
                            audio: false,
                            video: true
                          }}
                          dataAvailableTimeout={500}
                          showReplayControls
                          timeLimit={10000}
                          replayVideoAutoplayAndLoopOff
                          onRecordingComplete={async (videoBlob) => {
                            const videoBase64 = await blobToBase64(videoBlob);
                            setBase64String(videoBase64);
                            setUploadKycVideo(true);
                          }}
                        />
                      </>
                      :
                      <>
                        <h3 className="home-title mb-2"><span style={{ color: "#268e9c", fontWeight: "100", fontSize: "0.7em" }}>Video Uploaded. </span><span style={{ color: "#2e3664", fontWeight: "100", fontSize: "0.7em" }}>Checkout how you came out</span></h3>
                        <div>
                          <VideoPlayer base64String={base64String.replace} />
                        </div>
                      </>

                  }

                  {uploadKycVideo ?
                    <Button className='btn mt-1 ' size='small' label={<><strong color='blue'>Upload Video</strong></>} severity="info" rounded outlined raised
                      onClick={() => { setIsSkeletonLoading(true); uploadKyc(); }}
                    /> : <></>
                  }
                </div>
            }

          </div>
        </div>

        <Dialog header="Add new beneficiary" visible={isNewBenDialogvisible} style={{ width: '600px' }} onHide={() => setIsNewBenDialogvisible(false)} className='glassmorphic-container-curve'>
          <Toast ref={toast} />
          {!isSkeletonLoading ? <form method="post" name="myForm" onSubmit={handleSubmit}>
            <p id="error-msg" style={{ opacity: 10 }}> </p>
            <div id="simple-msg"></div>
            <div className="card flex flex-column md:flex-row gap-3 m-4" style={{ border: "none" }}>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <input name="fname" id="fname" type="text"
                      value={formData.fname}
                      onChange={handleChange}
                      className="form-control contact-form input-custom-style" placeholder="First Name" />
                    {formErrors.fname && <p className="text-danger">{formErrors.fname}</p>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <input name="lname" id="lname" type="text"
                      value={formData.lname}
                      onChange={handleChange}
                      className="form-control contact-form input-custom-style" placeholder="Last Name" />
                    {formErrors.lname && <p className="text-danger">{formErrors.lname}</p>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <input name="address1" id="address1" type="text"
                      value={formData.address1}
                      onChange={handleChange}
                      className="form-control contact-form input-custom-style" placeholder="Address Name" />
                    {formErrors.address1 && <p className="text-danger">{formErrors.address1}</p>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <input name="city" id="city" type="text"
                      value={formData.city}
                      onChange={handleChange}
                      className="form-control contact-form input-custom-style" placeholder="City" />
                    {formErrors.city && <p className="text-danger">{formErrors.city}</p>}
                  </div>
                </Col>
              </Row>
              <Col lg={12}>
                <label className='text-muted'>Country</label>
                <div
                  id="countryFlag"
                  className="marginBottom form-control contact-form"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <ReactCountryFlag
                    countryCode={
                      countryData.countryVal ? countryData.countryVal.value : ""
                    }
                    svg
                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                    cdnSuffix="svg"
                    title={countryData.countryVal ? countryData.countryVal.value : ""}
                  />
                  <div
                    style={{ marginLeft: "10px", color: "black", width: "100%" }}
                  >
                    <Select
                      styles={{
                        control: provided => ({
                          ...provided,
                          border: 'none',  // Set border to none
                        }),
                      }}
                      name='countryVal'
                      isSearchable={true}
                      options={countryData.optionsCountries}
                      value={countryData.value}
                      onChange={handleChangeCountry}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <label className='text-muted m-1'>Select Prefered Collection Point</label>
                <div id="countryFlag" className="marginBottom form-control contact-form" style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginLeft: "10px", color: "black", width: "100%" }} >
                    <Select
                      styles={{
                        control: provided => ({
                          ...provided,
                          border: 'none',  // Set border to none
                        }),
                      }}
                      name='collectionPoints'
                      isSearchable={true}
                      options={collectionPointsOptions}
                      value={selectedCollectionPoints}
                      onChange={handleCollectionPoints}
                    />
                  </div>
                </div>
              </Col>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <PhoneInput
                      name="mobile"
                      country={"zw"}
                      value={formData.mobile}
                      onChange={handleChangePhone}
                      required
                      className="input-custom-style"
                      inputStyle={{
                        height: "50px",
                        width: "100%",
                        borderColor: "rgb(222,226,230)"
                      }}
                    />
                    {formErrors.mobile && <p className="text-danger">{formErrors.mobile}</p>}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <input name="email" id="email" type="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="form-control contact-form input-custom-style" placeholder="Email Address" />
                    {formErrors.email && <p className="text-danger">{formErrors.email}</p>}
                  </div>
                </Col>
              </Row>

            </div>
            <Row className="justify-content-center m-3" >
              <div className="text-center">
                <Button onClick={() => setIsNewBenDialogvisible(false)} className="p-button-text justify-content-center text-primary fw-bold  mt-3">
                  Cancel
                </Button>
                <Button onClick={(e) => { handleSubmit(e) }} className="btn bg-primary me-2 ">
                  Submit
                </Button>

              </div>
            </Row>
          </form> : <LoadingSkeleton />}
        </Dialog>



        <Dialog header="Edit Beneficiary" visible={isEditBenDialogvisible} style={{ width: '600px' }} onHide={() => setIsEditBenDialogvisible(false)} className='glassmorphic-container-curve'>
          <Toast ref={toast} />
          {!isSkeletonLoading ? <form method="post" name="myEditForm" onSubmit={handleEditSubmit}>
            <p id="error-msg" style={{ opacity: 10 }}> </p>
            <div id="simple-msg"></div>
            <div className="card flex flex-column md:flex-row gap-3 m-4" style={{ border: "none" }}>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <input name="fname" id="fname" type="text"
                      value={formEditData.fname}
                      onChange={handleEditChange}
                      className="form-control contact-form input-custom-style" placeholder="First Name" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <input name="lname" id="lname" type="text"
                      value={formEditData.lname}
                      onChange={handleEditChange}
                      className="form-control contact-form input-custom-style" placeholder="Last Name" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <PhoneInput
                      name="mobile"
                      country={"zw"}
                      value={formEditData.mobile}
                      onChange={handleEditChangePhone}
                      required
                      className="input-custom-style"
                      inputStyle={{
                        height: "50px",
                        width: "100%",
                        borderColor: "rgb(222,226,230)"
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <input name="email" id="email" type="email"
                      value={formEditData.email}
                      onChange={handleEditChange}
                      className="form-control contact-form input-custom-style" placeholder="Email Address" />
                  </div>
                </Col>
              </Row>

            </div>
            <Row className="justify-content-center m-3" >
              <div className="text-center">
                <Button onClick={() => setIsEditBenDialogvisible(false)} className="p-button-text justify-content-center text-primary fw-bold  mt-3">
                  Cancel
                </Button>
                <Button onClick={(e) => { handleEditSubmit(e) }} className="btn bg-primary me-2 ">
                  Submit
                </Button>

              </div>
            </Row>
          </form> : <LoadingSkeleton />}
        </Dialog>


        <Dialog header="Incomplete Profile - Add Proof of address" visible={isProofOfAddress} style={{ width: '600px' }} onHide={() => setIsProofOfAddress(false)} >
          <Toast ref={toast} />
          {!isSkeletonLoading ? <form method="post" name="myForm" onSubmit={uploadPOA}>
            <p id="error-msg" style={{ opacity: 10 }}> </p>
            <div id="simple-msg"></div>
            <div className="card flex flex-column md:flex-row gap-3 m-4" style={{ border: "none" }}>
              <Row>
                <Col lg={12}>
                  <div className="form-group">
                    <input name="poa" id="poa" type="file"
                      onChange={handleSelectPoa}
                      className="form-control contact-form input-custom-style" placeholder="Proof of residence" />
                      {/* {base64String && <img src={`data:image/jpeg;base64,${base64String}`} alt="preview" />} */}
                  </div>
                </Col>
              </Row>
            </div>
            <Row className="justify-content-center m-2" >
              <div className="text-center">
                <Button onClick={(e)=>{uploadPOA(e)} } className="btn bg-primary me-2 ">
                  Submit
                </Button>

              </div>
            </Row>
          </form> : <LoadingSkeletonPoa />}
        </Dialog>

      </div>
    </React.Fragment>
  )
}

export default Section;
