/* eslint-disable no-unused-vars */
import React from 'react'
// eslint-disable-next-line no-unused-vars
import Navbar1 from '../../layout/Navbar';
import Section from './section';
import NavbarInternal from '../../layout/NavbarInternal';

const login = () => {
    return (
        <React.Fragment>
            <NavbarInternal />
            <Section />
        </React.Fragment>
    )
}

export default login;