/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav
} from 'react-bootstrap';

// import Image
import logoLight from "../assets/images/logo-light.png";
import logoDark from "../assets/images/logo-dark.png";
import { Link } from 'react-router-dom';


const Footer = () => {

    // light-dark mode button 
    // useEffect(() => {
    //     const handleClick = () => {
    //         let theme = localStorage.getItem("theme");
    //         if (theme === "light" || theme === "") {
    //             document.body.setAttribute("data-layout-mode", "dark");
    //             localStorage.setItem("theme", "dark");
    //         } else {
    //             document.body.removeAttribute("data-layout-mode");
    //             localStorage.setItem("theme", "light");
    //         }
    //     };

    //     const btn = document.getElementById("mode");
    //     btn.addEventListener("click", handleClick);

    //     return () => {
    //         btn.removeEventListener("click", handleClick);
    //     };
    // }, []);

    // Color Switcher

    // const setColor = (theme) => {
    //     const colorOpt = document.getElementById('color-opt');
    //     colorOpt.href = `/appspery/react/css/colors/${theme}.css`;
    // };

    // Switcher

    const [leftPosition, setLeftPosition] = useState('-189px');

    const toggleSwitcher = () => {
        if (leftPosition === '-189px') {
            setLeftPosition('-0px');
        } else {
            setLeftPosition('-189px');
        }
    };


    return (
        <React.Fragment>
            {/* <section className="footer overflow-hidden  ">
                <Container>
                    <Row className="justify-content-center mt-3">
                        <Col lg={10} className="text-center mt-1">
                            <Navbar.Brand className="logo text-uppercase" href="/">
                                <img src={logoLight} className="logo-light" alt="" height="30" />
                                <img src={logoDark} className="logo-dark" alt="" height="30" />
                            </Navbar.Brand>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className="bottom-footer py-4 glassmorphic-container" style={{background:"rgb(248,249,252)"}}>
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6}>
                        <p className="mb-0 text-center text-muted">
                        {new Date().getFullYear()} © Wiremit Ltd (FRN 1001697) is a PSD Agent of Transfa Pay Ltd (FRN 944767), authorised and regulated by the Financial Conduct Authority.
                            </p>
                       
                            {/* <p className="mb-0 text-center text-muted">
    
                                <Link to="#" target="_blank" className="text-muted"> ACK Solutions </Link>
                            </p> */}
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* light-dark mode button */}
            {/* <button to="3" id="mode" className="mode-btn text-white rounded-end">
                <i className="uil uil-brightness mode-dark mx-auto"></i>
                <i className="uil uil-moon bx-spin mode-light"></i>
            </button> */}



        </React.Fragment>
    )
}

export default Footer;