import React, { useState, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import {
  Row,
  Col
} from 'react-bootstrap';

import Select from "react-select";

import ReactCountryFlag from "react-country-flag";
import './styles.css'
import TransactionService from "../../services/transactionServices";
import AuthService from '../../services/authServices';
import { Link } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";


function IFrameCurrencyConvertorExternal() {
  const receiveOptions = [
    { value: 'Cash Pickup', label: 'Cash Pickup' },
    // { value: 'Bank Transfer', label: 'Bank Transfer' },
    // { value: 'Airtime', label: 'Airtime' },
    // { value: 'Bill Payment', label: 'Bill Payment' }
  ];

  const [totalCharges, setTotalCharges] = useState(2.5);
  const [amount, setAmount] = useState(1);
  const [fromCurrency, setFromCurrency] = useState("GBP");
  const [toCurrency, setToCurrency] = useState("USD");
  const [output, setOutput] = useState(1.24);
  const [currentRate, setCurrentRate] = useState(1.24);
  const [displayFees, setDisplayFee] = useState(false);
  const [loader, setLoader] = useState(false);

  const [receiveOptionsData, setreceiveOptionsData] = useState({
    receiveOptions: receiveOptions,
    receiveOptionVal: receiveOptions[0]
  });

  const handleChangeReceiveOption = (value, data) => {
    console.log(data.name)
    if (data && data.name) {
      setreceiveOptionsData({
        ...receiveOptionsData,
        'receiveOptionVal': value,
      });
      setDisplayFee(true);
    }
  };

  const fromOptions = [{ value: 'GBP', label: 'GBP' }];
  const sendOptions = [{ value: 'USD', label: 'USD' }];

  const calculateOutput = async () => {
    try {
      setLoader(true)

      if (!localStorage.getItem('ratesSessionToken')) {
        await AuthService.login({
          "username": process.env.REACT_APP_RATES_USERNAME,
          "password": 'Pa$sWord1wr' 
        });

        localStorage.removeItem('sessionToken');
        localStorage.removeItem('user');
      }

      const response = await TransactionService.getRates('Zimbabwe', fromCurrency, toCurrency, amount, 'SOURCE');

      let CurrencyRate
      if (response.body.status === "FAIL") {
       
        localStorage.removeItem('ratesSessionToken')
        calculateOutput();
        CurrencyRate = currentRate;
      } else {
          CurrencyRate = response.body.result.rate;
      }

      const output = (amount * CurrencyRate).toFixed(0);

      setOutput(output);
      setCurrentRate(CurrencyRate);
      setTotalCharges(response.body.result.total_charges)
      setLoader(false)
    } catch (error) {
      console.log(error)
      setLoader(false)
    }
  };


  const LoadingSkeleton = () => {
    return (
      <div className="border-round border-1 surface-border p-2 surface-card">
        <Skeleton width="21=2rem" height="3rem" className=""></Skeleton>
      </div>)
  }



  useEffect(() => {
    calculateOutput();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromCurrency, toCurrency, amount]);


  return (
    <>
      {/* <li className="list-inline-item"> */}
      <div className='row'>
        <div className="col-md-3 wiremit-rates-iframe-container-curve-blue-external">
          <div className="mt-2 mb-5 rounded-container">
            <Row>
              <Col>
                <label className='mb-2 ' style={{ textAlign: "left", fontWeight: "100px", fontSize: '0.9em' }}>You send</label>
                <Row >
                  <Col lg={6}>
                    <div className="form-group mb-4">
                      <CurrencyInput
                        value={amount}
                        onValueChange={(amount) => setAmount(amount)}
                        intlConfig={{ locale: "en-US", currency: fromCurrency }}
                        allowDecimals={true}
                        allowNegativeValue={false}
                        className="form-control contact-form"
                        style={{
                          fontSize: '1.4em',
                          lineHeight: '1.8em',
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div
                      id="countryFlag"
                      className=" form-control contact-form"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <ReactCountryFlag
                        className="emojiFlag"
                        countryCode={fromCurrency.substring(0, 2)}
                        style={{
                          fontSize: '1.7em',
                          lineHeight: '1em',
                        }}
                        aria-label={fromCurrency}
                      />
                      <div
                        style={{ marginLeft: "10px", color: "black", width: "100%" }}
                      >
                        <Select
                          id="from"
                          value={{ value: fromCurrency, label: fromCurrency }}
                          onChange={(selectedOption) => setFromCurrency(selectedOption.value)}
                          options={fromOptions}
                          styles={{
                            control: provided => ({
                              ...provided,
                              border: 'none',
                              fontSize: '1.3em',
                            }),
                          }}
                        />
                      </div>
                    </div>

                  </Col>
                </Row>

 <Row className="mb-4">
  <Col lg={12}>
    <div className="form-group">
      <label className='mb-2 ' style={{ textAlign: "right", fontWeight: "100px", fontSize: '0.9em' }}>They Receive</label>
      
      {
  loader?<LoadingSkeleton/>:
      <div
        id="countryFlag"
        className=" form-control contact-form"
        style={{ display: "flex", alignItems: "center" }}
      >

        <ReactCountryFlag
          className="emojiFlag"
          countryCode={toCurrency.substring(0, 2)}
          style={{
            fontSize: '2em',
            lineHeight: '1em',
          }}
          aria-label={toCurrency}
        />

        <div
          style={{ marginLeft: "10px", color: "black", width: "100%" }}
        >
          <Select
            id="from"
            value={{ value: toCurrency, label: toCurrency + " " + output }}
            onChange={(selectedOption) => setToCurrency(selectedOption.value)}
            options={sendOptions}
            styles={{
              control: provided => ({
                ...provided,
                border: 'none',
                fontSize: '1.3em',
              }),
            }}
          />

        </div>
      </div>
}
    </div>
  </Col>
</Row>

               

                <Row className="mb-4">
                  <Col md={5}>
                    <label className='mt-2 ' style={{ textAlign: "left", fontWeight: "100px", fontSize: '0.9em' }}>Receive Method</label>
                  </Col>
                  <Col >
                    <div className="form-group">
                      <Select
                        styles={{
                          control: provided => ({
                            ...provided,
                            border: 'none',  // Set border to none
                          }),
                        }}
                        className="form-control contact-form"
                        name='receiveOptionVal'
                        isSearchable={false}
                        options={receiveOptionsData.receiveOptions}
                        value={receiveOptionsData.value}
                        onChange={handleChangeReceiveOption}
                      />
                    </div>
                  </Col>

                </Row>
                
                  <div>
                    <Row className="mb-1">
                      <Col className="con-title">Fee </Col><Col className="con-value">{totalCharges} {fromCurrency}</Col>
                    </Row>
                    <Row className="mb-1">
                      <Col className="con-title">Total to pay </Col><Col className="con-value">{Math.ceil(((totalCharges * 1) + (amount * 1))).toFixed(2)} {fromCurrency}</Col>
                    </Row>
                    {/* <Row className="mb-1">
                      <Col className="con-title">Transfer Time </Col><Col className="con-value " style={{ color: 'green', fontStyle: "italic" }}>Instant</Col>
                    </Row> */}
                    <Row className="mb-1">
                      <Col className="con-title">Collection Points </Col><Col className="con-value " style={{ color: 'green', fontSize: '14px' }}>Wiremit and Clickacash Branches</Col>
                    </Row>
                    { displayFees ?
                      <Row className="mb-1">
                        <div className="ms-auto inline-flex text-center mt-3">
                          <Link target='_blank' to="https://uk.wiremit.money" className="btn bg-primary inline-flex align-items-center">Send Money Now</Link>
                        </div>
                      </Row>: <></>
                    }
                  </div> 
              </Col>
            </Row>
            <Row className="mt-5">
              <div className="bottom-div">
                <p>Exchange Rate</p>
                <p className="rate-font">1 {fromCurrency} = {currentRate} {toCurrency}</p>
              </div>

            </Row>

          </div>
        </div>
      </div>
      {/* </li> */}
    </>
  );
}

export default IFrameCurrencyConvertorExternal;
