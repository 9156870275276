import React, { useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { Link } from "react-router-dom";
import logoLight from "../../assets/images/logo-light.png";
import AuthService from "../../services/authServices";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Toast } from "primereact/toast";
import CurrencyConvertor from "./currencyConvertor";
import "./styles.css";

import TagManager from "react-gtm-module";

import useMediaQuery from "../../useMediaQuery"

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "wiremit-international",
    page: "signin",
  },
};

const Section = () => {
  TagManager.dataLayer(tagManagerArgs);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  const validateForm = () => {
    let valid = true;
    const errors = {};

    // Validate First Name
    if (!formData.username.trim()) {
      errors.username = "Email is required";
      valid = false;
    }

    // Validate Last Name
    if (!formData.password.trim()) {
      errors.password = "Password is required";
      valid = false;
    }

    if (!formData.username.trim()) {
      errors.username = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.username)) {
      errors.username = "Invalid email address";
      valid = false;
    }

    // Set form errors
    setFormErrors(errors);

    return valid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (!value.trim()) {
      setFormErrors({
        ...formErrors,
        [name]: name + " is required",
      });
    } else {
      setFormErrors({
        ...formErrors,
        [name]: "",
      });
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validateForm();

    if (!isValid) {
      toast.current.show({
        severity: "error",
        summary: "Login Failed",

        detail: (
          <div>
            {formErrors.username && <div>{formErrors.username}</div>}
            {formErrors.password && <div>{formErrors.password}</div>}
          </div>
        ),
        life: 4000,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await AuthService.login(formData);
      setLoading(false);
      if (response.status === "SUCCESS") {
        navigate("/dashboard");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Login Failed",
          detail: "Invalid Credentials.",
          life: 4000,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Login Failed",
        detail: error,
        life: 4000,
      });
    }
  };

  const LoadingSkeleton = () => {
    return (
      <div className="border-round border-1 surface-border p-4 surface-card">
        <div className="flex mb-3">
          <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
          <div>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
            <Skeleton width="5rem" className="mb-2"></Skeleton>
            <Skeleton height=".5rem"></Skeleton>
          </div>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <section
        className="home-1 bg-home d-flex bg-light"
        id="home"
        style={{ height: "auto" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={12} className=" mb-5 mt-0 mt-md-5 pt-0 pt-md-5">
              <div className=" home-heading">
              {!isMobile &&  <>
                <h3 className=" text-center home-title">
                  <span style={{ color: "#2e3664", fontWeight: "400" }}>
                    Send Money Swiftly & Safely. Anywhere, Anytime!
                  </span>
                  <br />
                </h3>
                <p
                  className="text-center text-muted para-desc"
                  style={{ fontSize: "1em", fontWeight: "300" }}
                >
                  Get unbeatable exchange rates and minimal fees, leaving more
                  in your pocket. Experience the ultimate solution for your
                  money transfer needs.
                </p>
                </> }

                {loading ? (
                  <LoadingSkeleton />
                ) : (
                  <ul className="mt-4 list-unstyled mb-0 align-items-center">
                    <li className="list-inline-item">
                      {/* ... (previous list-inline-item code) */}
                    </li>

                    <li className="list-inline-item">
                      <div className="row">
                        <div className="col-md-1 mt-4 ml-4"></div>
                        {/* signin form */}
                        <div className="col-md-5 glassmorphic-container-curve text-center">
                          <div className="mt-5 mb-5 rounded-container">
                            <Container>
                              <Col lg={12}>
                                <img
                                  src={logoLight}
                                  className="logo-light"
                                  alt=""
                                  height="60"
                                />
                                <p
                                  style={{
                                    fontSize: "1.5em",
                                    fontWeight: "500",
                                  }}
                                >
                                  Sign In
                                </p>
                              </Col>

                              <Row className="align-items-center justify-content-center">
                                <Col lg={12}>
                                  <div className="contact-box">
                                    <div className="custom-form ">
                                      <form
                                        method="post"
                                        name="myForm"
                                        onSubmit={handleSubmit}
                                      >
                                        <p
                                          id="error-msg"
                                          style={{ opacity: 10, color: "red" }}
                                        >
                                          {" "}
                                        </p>
                                        <Row className="">
                                          <Col lg={12}>
                                            <div className="mb-3 form-group">
                                              <input
                                                name="username"
                                                id="username"
                                                type="text"
                                                value={formData.username}
                                                onChange={handleChange}
                                                className="form-control contact-form input-custom-style"
                                                placeholder="Email address"
                                              />
                                              {formErrors.username && (
                                                <p
                                                  className="text-danger text-left"
                                                  style={{
                                                    fontSize: "0.9em",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  *{formErrors.username}
                                                </p>
                                              )}
                                            </div>
                                          </Col>

                                          <Col lg={12}>
                                            <div className="mb-4 form-group">
                                              <input
                                                name="password"
                                                id="password"
                                                type={
                                                  showPassword
                                                    ? "text"
                                                    : "password"
                                                }
                                                value={formData.password}
                                                onChange={handleChange}
                                                className="form-control contact-form input-custom-style"
                                                placeholder="Password"
                                              />
                                              <i
                                                id="icon"
                                                className=" password-toggle"
                                                type="button"
                                                onClick={() =>
                                                  setShowPassword(!showPassword)
                                                }
                                              >
                                                {showPassword ? (
                                                  <FaEyeSlash />
                                                ) : (
                                                  <FaEye />
                                                )}
                                              </i>
                                              {formErrors.password && (
                                                <p
                                                  className="text-danger text-left"
                                                  style={{
                                                    fontSize: "0.9em",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  *{formErrors.password}
                                                </p>
                                              )}
                                            </div>
                                          </Col>
                                        </Row>

                                        <Row className="justify-content-center">
                                          <div className="text-center">
                                            <Button
                                              onClick={(e) => handleSubmit(e)}
                                              className="btn btn-lg bg-primary me-2"
                                              style={{
                                                fontSize: "1.1em",
                                                fontWeight: "500",
                                              }}
                                            >
                                              Sign-in
                                            </Button>
                                            <Link
                                              to="/forgot-password"
                                              className="justify-content-center text-primary mt-3"
                                            >
                                              Forgot password?
                                            </Link>
                                            <Link
                                              to="/new-user"
                                              className="justify-content-center text-primary fw-bold mt-4"
                                            >
                                              Create New Profile{" "}
                                              <i className="uil uil-angle-right-b align-middle"></i>
                                            </Link>
                                          </div>
                                        </Row>
                                      </form>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </div>

                        <div className="col-md-1 mt-4 ml-4"></div>

                        {/* CurrencyConvertor */}
                        <div className="col-md-5  text-center">
                          <CurrencyConvertor />
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </Col>
            <Col lg={12}>
              <div className="home-circle">
                {/* ... (previous home-circle code) */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
