import React from 'react';

const VideoPlayer = ({ base64String }) => {
  const cleanedBase64String = base64String.replace(/^[\n\s]+/, '');
    return (
      <div>
        <video controls >
          <source src={`${cleanedBase64String}`} type="video/webm" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };
  
  export default VideoPlayer;