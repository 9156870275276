import React from 'react'
import Navbar1 from '../../layout/Navbar';
import Section from './section';
import NavBar from '../../components/NavBarLanding';



const home = () => {
  return (
    <React.Fragment>
       <NavBar page="login"/>
   {/*  <Navbar1 /> */}
      <Section />
    
    </React.Fragment>
  )
}

export default home;